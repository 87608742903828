import { config } from '@jane/shared/config';
import type { AppMode } from '@jane/shared/models';
import { MixpanelClass } from '@jane/shared/util';

import type { TrackedEvent } from './mixpanelEvents';

let dmMixpanel: MixpanelClass;

type DmAppMode = AppMode | 'ad-manager';

interface DmMixpanelCommonProps {
  appMode: DmAppMode;
  janeDeviceId: string | null | undefined;
  storeId: number | null;
}

export const initDmMixpanel = (props: DmMixpanelCommonProps) => {
  if (dmMixpanel === undefined && config.dmMixpanelToken) {
    dmMixpanel = new MixpanelClass({
      projectName: 'dm',
      token: config.dmMixpanelToken,
    });
  }
  resetDmMixpanel(props);
};

const resetDmMixpanel = (props: DmMixpanelCommonProps) => {
  if (dmMixpanel === undefined) return;
  dmMixpanel.reset();
  dmMixpanel.register({
    buildVersion: config.buildVersion.substring(0, 7),
    ...props,
  });
};

export const track = (event: TrackedEvent) => {
  const { event: eventName, ...meta } = event;
  if (config.nodeEnv === 'development') {
    // eslint-disable-next-line no-console
    console.log('Tracking (DM)', eventName, meta);
  } else if (dmMixpanel === undefined) {
    // eslint-disable-next-line no-console
    console.log(`DM Mixpanel not initialized when tracking event=${eventName}`);
  } else {
    dmMixpanel.track(eventName, meta);
  }
};
