import type { CategoryOption } from '@jane/shared-b2b/components';

export const MOCK_PRODUCT_CATEGORIES: CategoryOption[] = [
  {
    label: 'Edibles',
    value: 'edibles',
    subItems: [
      { label: 'Sub Edible 1', value: 'sub-edible-1' },
      { label: 'Sub Edible 2', value: 'sub-edible-2' },
      { label: 'Sub Edible 3', value: 'sub-edible-3' },
    ],
  },
  {
    label: 'Extract',
    value: 'extract',
    subItems: [],
  },
  {
    label: 'Flower',
    value: 'flower',
    subItems: [
      { label: 'Sub Flower 1', value: 'sub-flower-1' },
      { label: 'Sub Flower 2', value: 'sub-flower-2' },
      { label: 'Sub Flower 3', value: 'sub-flower-3' },
      { label: 'Sub Flower 4', value: 'sub-flower-4' },
    ],
  },
  {
    label: 'Gear',
    value: 'gear',
    subItems: [],
  },
  {
    label: 'Grow',
    value: 'grow',
    subItems: [],
  },
  {
    label: 'Merch',
    value: 'merch',
    subItems: [],
  },
  {
    label: 'Pre-roll',
    value: 'pre-roll',
    subItems: [],
  },
  {
    label: 'Tinctures',
    value: 'tinctures',
    subItems: [],
  },
  {
    label: 'Vape',
    value: 'vape',
    subItems: [],
  },
];
