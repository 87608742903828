import styled from '@emotion/styled';
import type * as ReactTable from '@tanstack/react-table';
import React, { useState } from 'react';

import type { Product } from '@jane/ad-manager/data-access';
import { parseIds } from '@jane/ad-manager/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import {
  Box,
  Button,
  ButtonToggle,
  Flex,
  Link,
  Modal,
  TextAreaField,
  Typography,
} from '@jane/shared/reefer';

import { ProductTable } from './ProductTable';
import { ProductTableColumnFilter } from './ProductTableColumnFilter';
import { ProductTableSearch } from './ProductTableSearch';

const ButtonToggleStylesOverrideWrapper = styled.div(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.colors.grays.ultralight,
    borderRadius: theme.borderRadius.sm,

    '> button': {
      borderRadius: theme.borderRadius.sm,
      paddingLeft: '24px',
      paddingRight: '24px',
      marginRight: 0,
    },
  },
}));

export type ProductColumnWithMeta = {
  meta?: {
    filterLabel?: string;
    filterValues?: string[];
  };
} & ReactTable.ColumnDef<Product>;

const NoProductsFound = ({ searchMode }: { searchMode: ProductSearchMode }) => (
  <Box
    width="100%"
    height="512px"
    border="grays-light"
    borderRadius="sm"
    data-testid="no-products-found"
  >
    <Flex
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="header-bold">No Products available</Typography>
      <Typography>
        As you search for
        {searchMode === 'productId' ? ' IDs' : ' product names'}, products will
        be displayed here.
      </Typography>
    </Flex>
  </Box>
);

const ProductIdsSearch = ({
  setSearchIds,
}: {
  setSearchIds: (ids: string[]) => void;
}) => {
  const [ids, setIds] = useState('');

  const handleSubmit = () => {
    const productIds = parseIds(ids);
    setSearchIds(productIds);
  };

  return (
    <Flex gap={16} alignItems="center">
      <TextAreaField
        label="Product IDs"
        name="search_ids_input"
        autocomplete="off"
        placeholder="Enter Product IDs"
        defaultValue={ids}
        autoFocus
        labelHidden
        onChange={(val) => setIds(val)}
        enterKeyHint="search"
        width={416}
      />
      <Button variant="secondary" label="Search" onClick={handleSubmit} />
    </Flex>
  );
};

type ProductSearchMode = 'productName' | 'productId';

interface Props {
  fetchMore?: () => void;
  hasChanges: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  onDiscardChanges: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  open: boolean;
  setSearchIds: (ids: string[]) => void;
  table: ReactTable.Table<Product>;
}

export const ProductTableModal = ({
  isLoading,
  isFetching,
  fetchMore,
  hasChanges,
  onDiscardChanges,
  onSave,
  open,
  setSearchIds,
  table,
}: Props) => {
  const [searchMode, setSearchMode] =
    useState<ProductSearchMode>('productName');

  const isEmptyResults = table.getRowModel().rows.length === 0;
  const isNotFoundState = !isFetching && isEmptyResults;
  const isShowingTable = !isEmptyResults;

  const handleToggleSearchMode = (mode: ProductSearchMode) => {
    setSearchMode(mode);

    // reset filters and search ids
    table.resetColumnFilters();
    setSearchIds([]);
  };

  const handleFetchMore = fetchMore || (() => undefined);

  return (
    <ConfirmDiscardWrapper
      setOpen={onDiscardChanges}
      open={open}
      variant="flex"
      hasChanges={hasChanges}
    >
      <>
        <Modal.Header
          data-testid="product-selector-modal"
          title="Select Products"
          actions={
            <Button
              label="Save"
              onClick={() => onSave()}
              loading={isLoading}
              disabled={isLoading}
            />
          }
        />
        <Modal.Content padding={false}>
          <Flex
            mt={24}
            px={40}
            flexDirection="column"
            data-testid="product-table"
            gap={16}
            height={'100%'}
          >
            <Flex alignItems="center">
              <ButtonToggleStylesOverrideWrapper>
                <ButtonToggle
                  value={searchMode}
                  onChange={(val) =>
                    handleToggleSearchMode(val as ProductSearchMode)
                  }
                  full={false}
                >
                  <ButtonToggle.Button
                    label="Select products"
                    value="productName"
                  />
                  <ButtonToggle.Button
                    label="By Product IDs"
                    value="productId"
                  />
                </ButtonToggle>
              </ButtonToggleStylesOverrideWrapper>
            </Flex>
            {searchMode === 'productName' && (
              <Flex gap={16} alignItems="center">
                <ProductTableSearch column={table.getColumn('name')} />
                {table
                  .getAllColumns()
                  .filter((col) => col.getCanFilter())
                  .map((col) => (
                    <ProductTableColumnFilter
                      key={col.id}
                      column={col}
                      filterOptions={
                        (col.columnDef as ProductColumnWithMeta)?.meta
                          ?.filterValues ?? []
                      }
                    />
                  ))}
                <Link
                  onClick={() => {
                    table.resetColumnFilters();
                  }}
                >
                  Clear filters
                </Link>
              </Flex>
            )}
            {searchMode === 'productId' && (
              <ProductIdsSearch setSearchIds={setSearchIds} />
            )}
            {isNotFoundState && (
              <Box mb={24}>
                <NoProductsFound searchMode={searchMode} />
              </Box>
            )}
            {isShowingTable && (
              <ProductTable
                fetchMore={handleFetchMore}
                table={table}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            )}
          </Flex>
        </Modal.Content>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Typography color="grays-mid">
                {Object.keys(table.getState().rowSelection).length} products
                selected
              </Typography>
            </Box>
            <Flex gap={16}>
              <Button
                label="Select All"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(true)}
              />
              <Button
                label="Select none"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(false)}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </>
    </ConfirmDiscardWrapper>
  );
};
