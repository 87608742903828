import { flexRender } from '@tanstack/react-table';
import type { Table as ReactTable } from '@tanstack/react-table';
import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Button,
  ButtonToggle,
  Flex,
  Link,
  Loading,
  Modal,
  SearchField,
  Typography,
} from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';
import { pluralize } from '@jane/shared/util';

import { idConversions } from '../util/idConversions';
import { StyledButtonToggle, StyledTableRow } from './productSelect.styles';
import { ProductSelectFilter } from './productSelectFilter';

type ProductSearchMode = 'productName' | 'productId';

export interface ProductSelectModalProps<R> {
  /** Option to show search mode toggle */
  dualSearchMode?: boolean;
  /** Boolean to check if fetching next page of products */
  isFetchingNextPage: boolean;
  /** Boolean to show a loading state */
  isLoading?: boolean;
  /** Action fired when next page is requested */
  onNextPage: () => void;
  /** Action fired on save click */
  onSaveClick: () => void;
  /** Product table to be rendered */
  productTable: ReactTable<R>;
  /** Optional setState if using dual search mode to set product ids for search */
  setSearchIds?: Dispatch<SetStateAction<string[]>>;
  /** Optional title, defaults to "Select products" */
  title?: string;
}

export const ProductSelectModal = <R,>({
  dualSearchMode,
  isFetchingNextPage,
  isLoading,
  onNextPage,
  onSaveClick,
  productTable,
  setSearchIds,
  title = 'Select products',
}: ProductSelectModalProps<R>) => {
  const [searchMode, setSearchMode] = useState('productName');
  const [ids, setIds] = useState<string>('');

  const wrapperRef = useRef(null);
  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage) {
        onNextPage();
      }
    },
  });

  const noResults = !isLoading && productTable.getRowModel().rows.length === 0;
  const productNameColumn = productTable.getColumn('name');
  const productFilters = productTable
    .getVisibleLeafColumns()
    .filter((col) => col.getCanFilter());

  const onToggleSearchMode = (mode: ProductSearchMode) => {
    setSearchMode(mode);
    productTable.resetColumnFilters();
    setSearchIds && setSearchIds([]);
  };

  const onProductIdSearchSubmit = () => {
    const productIds = idConversions.fromStrings(ids);
    setSearchIds && setSearchIds(productIds);
  };

  return (
    <>
      <Modal.Header
        title={title}
        actions={
          <Button
            label="Save"
            disabled={Boolean(!productTable.getSelectedRowModel().rows.length)}
            onClick={onSaveClick}
          />
        }
      />
      <Box pt={40} pb={24} px={40}>
        {dualSearchMode && (
          <Box width="fit-content" pb={24}>
            <StyledButtonToggle
              value={searchMode}
              onChange={(mode) => onToggleSearchMode(mode as ProductSearchMode)}
              full={false}
            >
              <ButtonToggle.Button
                label="Select products"
                value="productName"
              />
              <ButtonToggle.Button label="By product id" value="productId" />
            </StyledButtonToggle>
          </Box>
        )}
        <Flex
          flexWrap="wrap"
          gap={16}
          alignItems="center"
          position="sticky"
          top={0}
          zIndex={15}
          id="product-select-filters"
          background="grays-white"
        >
          {searchMode === 'productName' ? (
            <>
              <SearchField
                borderRadius="sm"
                label="Search product name"
                name="product-name-search-field"
                placeholder="Search product name"
                onChange={(value) => productNameColumn?.setFilterValue(value)}
                disableMobileInputStyling
              />
              {productFilters.map((col) => (
                <ProductSelectFilter<R>
                  key={col.id}
                  column={col}
                  filterOptions={
                    Array.isArray(col.columnDef.meta?.filterValues)
                      ? col.columnDef.meta?.filterValues
                      : []
                  }
                />
              ))}
              <Link onClick={() => productTable.resetColumnFilters()}>
                Clear filters
              </Link>
            </>
          ) : (
            <Flex gap={16} alignItems="center">
              <SearchField
                label="Search by product ids"
                name="products-ids-search-field"
                autocomplete="off"
                placeholder="Search by product ids"
                defaultValue={ids}
                autoFocus
                labelHidden
                onChange={(productIds) => setIds(productIds)}
                width={416}
                disableMobileInputStyling
              />
              <Button
                variant="secondary"
                label="Search"
                onClick={onProductIdSearchSubmit}
              />
            </Flex>
          )}
        </Flex>
      </Box>
      <Modal.Content ref={wrapperRef} padding={false}>
        {!noResults ? (
          <Table
            style={{
              height: 'unset',
              padding: '0px 40px',
            }}
            maxWidth="100%"
            scrollable={false}
          >
            <Table.Head>
              {productTable.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeaderCell
                      key={header.id}
                      width={`${header.getSize()}px`}
                    >
                      {!header.isPlaceholder &&
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              {productTable.getRowModel().rows.map((row) => (
                <StyledTableRow key={row.id}>
                  {row.getVisibleCells().map((cell, i) => (
                    <Table.Cell key={cell.id}>
                      <div
                        ref={
                          i === row.getVisibleCells().length - 1
                            ? ref
                            : undefined
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </Table.Cell>
                  ))}
                </StyledTableRow>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Box width="100%" height="calc(100% - 24px)" mb={24} px={40}>
            <Flex
              height="100%"
              alignItems="center"
              justifyContent="center"
              border="grays-light"
              borderRadius="sm"
              position="relative"
            >
              {isLoading ? (
                <Loading />
              ) : (
                <Typography variant="header-bold">
                  No products to display
                </Typography>
              )}
            </Flex>
          </Box>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography color="grays-mid">
            {`${pluralize({
              number: productTable.getSelectedRowModel().rows.length,
              noun: 'product',
            })} selected`}
          </Typography>
          <Flex gap={16}>
            <Button
              label="Select all"
              variant="tertiary"
              onClick={() => productTable.toggleAllRowsSelected(true)}
              disabled={productTable.getIsAllRowsSelected() || noResults}
            />
            <Button
              label="Clear all selected"
              variant="tertiary"
              onClick={() => productTable.toggleAllRowsSelected(false)}
              disabled={
                !(
                  productTable.getIsSomeRowsSelected() ||
                  productTable.getIsAllRowsSelected()
                )
              }
            />
          </Flex>
        </Flex>
      </Modal.Footer>
    </>
  );
};
