import { track } from './mixpanel';

interface BaseEvent<E extends EventName> {
  event: E;
}
enum EventName {
  BranchIoError = 'BranchIo Error',
  FailedRequest = 'Failed Request',
  LoadedSmartSortProducts = 'Loaded Smart Sort Products',
  UnexpectedError = 'Unexpected Error',
  ZodError = 'Zod Error',
}
export type TrackedEvent =
  | BranchIoError
  | FailedRequest
  | UnexpectedError
  | ZodError;

type BranchIoError = BaseEvent<EventName.BranchIoError> & BranchIoErrorProps;
interface BranchIoErrorProps {
  message: string;
}
export const trackBranchIoError = (props: BranchIoErrorProps) => {
  track({
    event: EventName.BranchIoError,
    ...props,
  });
};

type FailedRequest = BaseEvent<EventName.FailedRequest> & FailedRequestProps;
interface FailedRequestProps {
  attemptedUrl: string;
  code: number;
  error: string;
}
export const trackFailedRequest = (props: FailedRequestProps) => {
  track({
    event: EventName.FailedRequest,
    ...props,
  });
};

type UnexpectedError = BaseEvent<EventName.UnexpectedError> &
  UnexpectedErrorProps;
interface UnexpectedErrorProps {
  error: unknown;
}
export const trackUnexpectedError = (props: UnexpectedErrorProps) => {
  track({
    event: EventName.UnexpectedError,
    ...props,
  });
};

type ZodError = BaseEvent<EventName.ZodError> & ZodErrorProps;
interface ZodErrorProps {
  message: string;
  name: string;
}
export const trackZodError = (props: ZodErrorProps) => {
  track({
    event: EventName.ZodError,
    ...props,
  });
};
