import { useEffect, useRef, useState } from 'react';

import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  Button,
  CheckboxField,
  DismissIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';
import type { Store } from '@jane/shared/types';

const StoresSelectionModal = ({
  setStoreIds,
  storeIds,
  type,
}: {
  setStoreIds: (storeIds: string[]) => void;
  storeIds: string[];
  type: 'inclusion' | 'exclusion';
}) => {
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [searchedStoreIds, setSearchedStoreIds] = useState<string[]>([]);
  const storeCacheRef = useRef<{
    [key: string]: Pick<
      Store,
      | 'address'
      | 'cart_limit_policy'
      | 'city'
      | 'id'
      | 'name'
      | 'recreational'
      | 'state'
      | 'zip'
    >;
  }>({});
  const storeCache = storeCacheRef.current;

  /**@TODO replace this with query for gold manager data */
  const stores = [
    {
      id: 1,
      name: "Dank Dave's - San Jose",
      address: '123 Main Street',
      city: 'Edison',
      state: 'NJ',
      zip: '11111',
      recreational: true,
      cart_limit_policy: null,
    },
    {
      id: 2,
      name: "Dank Dave's - Santa Cruz",
      address: '420 Utrecht Blvd',
      city: 'Santa Cruz',
      state: 'CA',
      zip: '22222',
      recreational: false,
      cart_limit_policy: null,
    },
    {
      id: 3,
      name: "Dank Dave's - Ojai",
      address: '300 California Street',
      city: 'Ojai',
      state: 'CA',
      zip: '33333',
      cart_limit_policy: null,
      recreational: false,
    },
  ];

  stores.forEach((store) => {
    if (storeCache[store.id.toString()]) return;
    storeCache[store.id.toString()] = store;
  });

  storeIds.sort((a, b) =>
    storeCache[a]?.name.localeCompare(storeCache[b]?.name)
  );

  return (
    <Flex flexDirection="column" gap={24}>
      <Button
        variant="tertiary"
        label="Select stores"
        data-testid={`${type}-stores-button`}
        onClick={() => setOpen(true)}
      />
      {storeIds && storeIds.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {storeIds.map(
            (storeId) =>
              storeCache[storeId] && (
                <Button
                  key={storeCache[storeId].name}
                  label={storeCache[storeId].name}
                  aria-label={`Remove ${storeCache[storeId].name}`}
                  startIcon={<DismissIcon size="sm" />}
                  variant={
                    type === 'inclusion' ? 'secondary' : 'destructive-secondary'
                  }
                  onClick={() =>
                    setStoreIds(
                      storeIds.filter(
                        (selectedStoreId) => selectedStoreId !== storeId
                      )
                    )
                  }
                />
              )
          )}
        </Flex>
      )}
      {open && (
        <StoreSelectModal
          storesData={stores}
          isFetchingStores={false}
          searchFilterPlaceholder="Search"
          selectedStoreIds={storeIds}
          onSubmit={(storeIds) => {
            setStoreIds(storeIds.map(String));
            setOpen(false);
          }}
          submitButtonType={SUBMIT_BUTTON_VARIANTS.save}
          initialSearchFilter={searchFilter}
          onSearchCallback={(s) => setSearchFilter(s)}
          searchedStoreIds={searchedStoreIds}
          onSearchedStoreIdsChange={setSearchedStoreIds}
          closeModal={() => setOpen(false)}
        />
      )}
    </Flex>
  );
};

export const StoresSelection = () => {
  const [showExcludeStores, setShowExcludeStores] = useState(false);
  const [inclusionStoreIds, setInclusionStoreIds] = useState<string[]>([]);
  const [exclusionStoreIds, setExclusionStoreIds] = useState<string[]>([]);

  const sameStoresError = inclusionStoreIds.some((storeId) =>
    exclusionStoreIds.includes(storeId)
  );

  useEffect(() => {
    if (!showExcludeStores) {
      setExclusionStoreIds([]);
    }
  }, [showExcludeStores]);

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">Stores to target</Typography>
      <StoresSelectionModal
        type="inclusion"
        storeIds={inclusionStoreIds}
        setStoreIds={setInclusionStoreIds}
      />
      {sameStoresError && (
        <Typography color="error">Same stores are not permitted.</Typography>
      )}
      <CheckboxField
        name="excludeStores"
        label="Exclude stores"
        onChange={setShowExcludeStores}
        my={12}
      />
      {showExcludeStores && (
        <>
          <StoresSelectionModal
            type="exclusion"
            storeIds={exclusionStoreIds}
            setStoreIds={setExclusionStoreIds}
          />
          {sameStoresError && (
            <Typography color="error">
              Same stores are not permitted.
            </Typography>
          )}
        </>
      )}
    </Flex>
  );
};
