import type { TableHeadProps } from './table.types';
import { TableSection } from './tableSection/tableSection';

export const TableHead = ({
  children,
  fixed = true,
  style,
}: TableHeadProps) => (
  <TableSection element="thead" fixed={fixed} style={style}>
    {children}
  </TableSection>
);
