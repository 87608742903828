import styled from '@emotion/styled';
import { useState } from 'react';

import {
  Button,
  Flex,
  Image,
  List,
  TrashIcon,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import type { Product } from './productSelection';
import type { BrandSpecialRulesVariant } from './productsSection';

export const StyledListItem = styled(List.Item)({
  ' > div': {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '47% 18.5% 18.5% 7%',
    alignItems: 'center',
    gap: '24px',
    ...spacing({ px: 24, py: 12 }),
    borderBottom: '1px solid var(--colors-grays-light)',
  },
});

export const StyledListHeader = styled.div({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '47% 18.5% 18.5% 7%',
  alignItems: 'center',
  gap: '24px',
  ...spacing({ px: 24, py: 12 }),
  borderBottom: '1px solid var(--colors-grays-light)',
});

interface SelectedProductsProps {
  onRemove: () => void;
  products: Product[];
  variant: BrandSpecialRulesVariant;
}

export const SelectedProducts = ({
  products,
  onRemove,
  variant,
}: SelectedProductsProps) => {
  const [showAll, setShowAll] = useState(false);

  const handleScroll = () => {
    const element = document.getElementById('product-list');
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const onShowToggle = () => {
    if (showAll) {
      setShowAll(false);
      handleScroll();
    } else {
      setShowAll(true);
    }
  };

  const productsToRender = showAll ? products : products.slice(0, 5);

  const renderedProducts = productsToRender.map((product) => (
    <StyledListItem key={product.id}>
      <Flex alignItems="center" gap={16}>
        <Image
          src={product.photos?.[0]?.urls?.small || ''}
          altText={`Product image for ${product.name}`}
          sizes="thumbnail"
          borderRadius="rounded"
          border
          height="64px"
          width="64px"
        />
        <Typography variant="body-bold" truncateAt="100%">
          {product.name}
        </Typography>
      </Flex>
      <Typography>{product.category || ''}</Typography>
      <Typography>{product.subcategory || ''}</Typography>
      <Button.Icon
        onClick={onRemove}
        icon={<TrashIcon />}
        label={`Remove ${product.name} from products for ${variant}`}
      />
    </StyledListItem>
  ));

  return (
    <Flex flexDirection="column">
      <StyledListHeader id="product-list">
        <Typography variant="caps" color="grays-mid">
          Item
        </Typography>
        <Typography variant="caps" color="grays-mid">
          Category
        </Typography>
        <Typography variant="caps" color="grays-mid">
          Subcategory
        </Typography>
        <Typography variant="caps" color="grays-mid"></Typography>
      </StyledListHeader>
      <List label={`Selected products for ${variant}`} pb={12}>
        {renderedProducts}
      </List>
      {products.length > 5 && (
        <Button
          label={showAll ? 'Show less' : 'Show all'}
          variant="secondary"
          onClick={onShowToggle}
          mt={12}
        />
      )}
    </Flex>
  );
};

// To be removed/replaced with factory once query is implemented
export const mockProducts: Product[] = [
  {
    id: '1',
    name: 'Product 1',
    category: 'Category 1',
    subcategory: 'Subcategory 1',
    photos: [],
    brand: '',
    lineage: '',
  },
  {
    id: '2',
    name: 'Product 2 with a really long name that goes on forever hello how are you',
    category: 'Category 2',
    subcategory: 'Subcategory 2',
    photos: [],
    brand: '',
    lineage: '',
  },
  {
    id: '3',
    name: 'Product 3',
    category: 'Category 3',
    subcategory: 'Subcategory 3',
    photos: [],
    brand: '',
    lineage: '',
  },
  {
    id: '4',
    name: 'Product 4',
    category: 'Category 4',
    subcategory: 'Subcategory 4',
    photos: [],
    brand: '',
    lineage: '',
  },
  {
    id: '5',
    name: 'Product 5',
    category: 'Category 5',
    subcategory: 'Subcategory 5',
    photos: [],
    brand: '',
    lineage: '',
  },
  {
    id: '6',
    name: 'Product 6',
    category: 'Category 6',
    subcategory: 'Subcategory 6',
    photos: [],
    brand: '',
    lineage: '',
  },
];
