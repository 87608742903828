import type { Dayjs } from 'dayjs';
import { useState } from 'react';

import type {
  CustomDateRangeFormData,
  RangeIdentifier,
} from '@jane/ad-manager/types';
import {
  formatDatepickerString,
  monthStart,
  nowInPacificTime,
  parsePacificDate,
  weekStart,
} from '@jane/ad-manager/util';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  ChevronDownIcon,
  Popover,
  RadioFieldGroup,
} from '@jane/shared/reefer';

import { CustomDateRangeSelector } from './CustomDateRangeSelector';

const getRangeIdentifier = (
  startDatePT: Dayjs,
  endDatePT: Dayjs | null
): RangeIdentifier => {
  const nowPT = nowInPacificTime();
  const rangeStartIsWeekStart = startDatePT.isSame(weekStart(), 'day');
  const rangeStartIsMonthStart = startDatePT.isSame(monthStart(), 'day');
  const rangeStartIs90DaysStart = startDatePT.isSame(
    nowPT.subtract(90, 'days'),
    'day'
  );
  if (endDatePT !== null) return 'custom-time-range';

  if (rangeStartIsWeekStart && rangeStartIsMonthStart) {
    return 'this-month';
  } else if (rangeStartIsMonthStart) {
    return 'this-month';
  } else if (rangeStartIsWeekStart) {
    return 'this-week';
  } else if (rangeStartIs90DaysStart) {
    return '90-days';
  } else {
    return 'custom-time-range';
  }
};

export interface DateRangeSelectorProps {
  endDatePT: string | null;
  onChangeDayRange: (start: string, end: string | null) => void;
  showValue?: boolean;
  startDatePT: string | null;
}

export const DateRangeSelector = ({
  startDatePT,
  endDatePT,
  onChangeDayRange,
  showValue,
}: DateRangeSelectorProps) => {
  const startDatePTObj = startDatePT
    ? parsePacificDate(startDatePT)
    : monthStart();
  const endDatePTObj = endDatePT ? parsePacificDate(endDatePT) : null;

  const range = getRangeIdentifier(startDatePTObj, endDatePTObj);
  const [selectedRange, setSelectedRange] = useState<RangeIdentifier>(range);
  const [isCustom, setIsCustom] = useState(range === 'custom-time-range');

  const onChangePresetDateRange = (rangeIdentifier: RangeIdentifier) => {
    let startDatePTObj = nowInPacificTime();

    if (rangeIdentifier === 'this-week') startDatePTObj = weekStart();
    if (rangeIdentifier === 'this-month') startDatePTObj = monthStart();
    if (rangeIdentifier === '90-days') {
      startDatePTObj = startDatePTObj.subtract(90, 'days');
    }

    onChangeDayRange(formatDatepickerString(startDatePTObj), null);

    setSelectedRange(rangeIdentifier);
  };

  const onSubmitCustomDateRange =
    (closePopover: () => unknown) => (formData: CustomDateRangeFormData) => {
      onChangeDayRange(formData.startDate, formData.endDate);
      closePopover();
      setSelectedRange('custom-time-range');
    };

  const dateRanges: Record<RangeIdentifier, string> = {
    'this-week': 'This week',
    'this-month': 'This month',
    '90-days': '90 days',
    'custom-time-range': 'Custom time range',
  };

  const radioFieldOptions = Object.keys(dateRanges).map<{
    id: RangeIdentifier;
    label: string;
    value: RangeIdentifier;
  }>((dateRangesKey) => ({
    id: dateRangesKey as RangeIdentifier,
    label: dateRanges[dateRangesKey as RangeIdentifier],
    value: dateRangesKey as RangeIdentifier,
  }));

  const labelText = showValue ? dateRanges[selectedRange] : 'Date range';

  return (
    <Popover
      label="Date range"
      target={
        <Button
          label={labelText}
          variant="tertiary"
          onClick={() => {
            if (selectedRange !== 'custom-time-range') setIsCustom(false);
          }}
          endIcon={<ChevronDownIcon size="sm" />}
        />
      }
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="Date range" maxHeight="600px">
          <RadioFieldGroup
            mt={12}
            mb={-12}
            autoFocus
            name="time-range"
            defaultChecked={selectedRange}
            onChange={(rangeIdentifier: RangeIdentifier) => {
              if (rangeIdentifier === 'custom-time-range') {
                setIsCustom(true);
                return;
              }

              setIsCustom(false);
              onChangePresetDateRange(rangeIdentifier);
              closePopover();
            }}
            options={radioFieldOptions}
          />
          {isCustom && (
            <CustomDateRangeSelector
              currentStartDate={formatDatepickerString(startDatePTObj)}
              currentEndDate={
                endDatePTObj ? formatDatepickerString(endDatePTObj) : undefined
              }
              onSubmitCustomDateRange={onSubmitCustomDateRange(closePopover)}
            />
          )}
        </Popover.Content>
      )}
    </Popover>
  );
};
