import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { AppMode, Store } from '@jane/shared/models';
import type { Store as ZStore } from '@jane/shared/types';
import { getSurfaceByAppMode } from '@jane/shared/util';

export enum RecommendedSortVersion {
  off = 'off',
  p0 = 'p0',
  p1 = 'p1',
}

const useGetRecommendedSortVersion = (): RecommendedSortVersion => {
  return useFlag<RecommendedSortVersion>(
    FLAGS.dmRecommendedSortVersion,
    RecommendedSortVersion.off
  );
};

export const useIsRecommendedSortEnabled = (
  store: Store | ZStore | undefined,
  appMode: AppMode
): boolean => {
  const flagEnabled =
    RecommendedSortVersion.off !== useGetRecommendedSortVersion();

  const surface = getSurfaceByAppMode(appMode);
  const areAdsEnabled = store?.recommended_sort_enablement?.[surface];

  return Boolean(flagEnabled && areAdsEnabled);
};
