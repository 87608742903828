import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import type { CustomDateRangeFormData } from '@jane/ad-manager/types';
import {
  formatDatepickerString,
  nowInPacificTime,
  validationMessages,
  validators,
} from '@jane/ad-manager/util';
import { Flex, Form } from '@jane/shared/reefer';

interface CustomDateRangeSelectorProps {
  currentEndDate?: string;
  currentStartDate: string;
  onSubmitCustomDateRange: (formData: CustomDateRangeFormData) => void;
}

export const CustomDateRangeSelector = ({
  currentEndDate = formatDatepickerString(nowInPacificTime()),
  currentStartDate,
  onSubmitCustomDateRange,
}: CustomDateRangeSelectorProps) => {
  return (
    <Form
      name="custom-range-filter"
      onSubmit={(formData) =>
        onSubmitCustomDateRange(formData as unknown as CustomDateRangeFormData)
      }
    >
      <Flex flexDirection="column" gap={12}>
        <CustomDateRangeSelectors
          defaultEndDate={currentEndDate}
          defaultStartDate={currentStartDate}
        />
        <Form.SubmitButton
          variant="secondary"
          label="Apply"
          full
          mt={4}
          mb={8}
        />
      </Flex>
    </Form>
  );
};

interface CustomDateRangeSelectorsProps {
  defaultEndDate: string;
  defaultStartDate: string;
}

const CustomDateRangeSelectors = ({
  defaultEndDate,
  defaultStartDate,
}: CustomDateRangeSelectorsProps) => {
  const { watch, getFieldState, trigger } = useFormContext<{
    endDate: string;
    startDate: string;
  }>();

  const [endDate, startDate] = watch(['endDate', 'startDate']);
  const minDate = formatDatepickerString(
    nowInPacificTime().subtract(90, 'days')
  );

  const validateStartDate = (startDate: string) => {
    return (
      validators.isDateRangeValid(startDate, endDate) ||
      validationMessages.invalidStartDateRange
    );
  };

  const validateEndDate = (endDate: string) => {
    return (
      validators.isDateRangeValid(startDate, endDate) ||
      validationMessages.invalidEndDateRange
    );
  };

  useEffect(() => {
    const { isTouched: isStartDateTouched } = getFieldState('startDate');
    if (isStartDateTouched) trigger('startDate');

    const { isTouched: isEndDateTouched } = getFieldState('endDate');
    if (isEndDateTouched) trigger('endDate');
  }, [endDate, getFieldState, startDate, trigger]);

  return (
    <>
      <Form.DateTimeField
        required
        label="Start date"
        name="startDate"
        defaultValue={defaultStartDate}
        min={minDate}
        validate={validateStartDate}
      />
      <Form.DateTimeField
        required
        label="End date"
        name="endDate"
        defaultValue={defaultEndDate}
        min={minDate}
        validate={validateEndDate}
      />
    </>
  );
};
