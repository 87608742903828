import clsx from 'clsx';
import type { CSSProperties, ReactNode } from 'react';
import { createContext } from 'react';

import { toCSSSize } from '../../utils';
import type { ReeferBaseProps } from '../../utils/baseProps.types';
import styles from './skeleton.module.css';

export interface SkeletonProps extends ReeferBaseProps {
  /** Animate all child Skeleton.Bones, defaults to `false` */
  animate?: boolean;

  /** Skeleton.Bones and any additional wrapping containers to create the required layout */
  children: ReactNode;

  /** Determines the flexDirection of the main Skeleton element */
  direction?: 'column' | 'row';

  /** Determines the height of the main Skeleton element, accepts any valid height property */
  height?: string | number;

  /** Determines the width of the main Skeleton element, accepts any valid width property */
  width?: string | number;
}

/**
 * Skeleton Context to pass 'animate' to Skeleton.Bones
 */

export const SkeletonContext = createContext({
  animate: true,
});

/**
 * Skeleton to build component load, save or error states
 */

export function Skeleton({
  animate = false,
  children,
  className,
  'data-testid': testId,
  direction = 'column',
  height = 'fit-content',
  id,
  style,
  width = '100%',
}: SkeletonProps) {
  return (
    <SkeletonContext.Provider value={{ animate }}>
      <div
        className={clsx(className, styles.skeleton)}
        id={id}
        role="presentation"
        style={
          {
            '--width': toCSSSize(width),
            '--height': toCSSSize(height),
            '--flex-direction': direction,
            ...style,
          } as CSSProperties
        }
        data-testid={testId}
      >
        {children}
      </div>
    </SkeletonContext.Provider>
  );
}
