import type { SearchFieldProps } from '../fields/field.types';
import { SearchField } from '../fields/searchField';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export type FormSearchFieldProps = Omit<SearchFieldProps, 'children'>;

/**
 * Search fields allow users to enter text into a UI to perform a search.
 *
 * Use this component *inside forms* for inputs of `type="search"`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE OF FORMS**
 *
 * *For a similar component for use outside of forms,
 * see [`SearchField`](/story/components-forms-fields-searchfield--default).*
 */

export function FormSearchField({
  autoFocus = false,
  debounceDelay = 300,
  defaultValue = '',
  disabled = false,
  autocomplete,
  helperText,
  isDebounced = true,
  label,
  labelHidden = true,
  name,
  onBlur,
  onChange,
  placeholder,
  'data-testid': testId,
  width = 'auto',
  ...props
}: FormSearchFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      name={name}
      render={({ children, field }) => (
        <SearchField
          autoFocus={autoFocus}
          debounceDelay={debounceDelay}
          defaultValue={defaultValue}
          disabled={disabled}
          autocomplete={autocomplete}
          helperText={helperText}
          isDebounced={isDebounced}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(value) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onUpdate={onChange}
          placeholder={placeholder}
          ref={field.ref}
          data-testid={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </SearchField>
      )}
    />
  );
}
