import { FlightDetail } from '@jane/ad-manager/components';
import { Loading } from '@jane/brands/components';

export const FlightDetailPage = () => {
  const isFlightLoading = false;
  const isFlightSuccess = true;
  const flight = {
    startDate: '3/1/2024',
    endDate: '3/31/2025',
    updatedDate: '2/29/2024',
    updatedBy: '[author]',
    isActive: true,
  };

  return (
    <>
      {isFlightLoading && <Loading />}
      {isFlightSuccess && flight && <FlightDetail />}
    </>
  );
};
