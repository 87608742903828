import { useInfiniteQuery } from '@tanstack/react-query';
import qs from 'qs';

import { brandsApi } from '@jane/brands/data-access';
import { createUseQueryHook } from '@jane/shared/data-access';
import { parseData } from '@jane/shared/util';

import type {
  Brand,
  FetchBrandsParams,
  UseInfiniteBrandsParams,
} from './brands.types';
import { BrandsResponseSchema } from './brands.types';

export const BRANDS_URL = '/jane_gold/brands';

const queryKeys = {
  brands: (params: FetchBrandsParams) => ['brands', params],
  infiniteBrands: (params: FetchBrandsParams) => ['infiniteBrands', params],
};

export const fetchBrands = async ({
  limit,
  offset,
  productBrandIds,
  query,
}: FetchBrandsParams) => {
  const queryParams = {
    limit,
    offset,
    product_brand_ids: productBrandIds,
    query,
  };

  const queryString = `?${qs.stringify(queryParams, {
    arrayFormat: 'brackets',
  })}`;

  const { data } = await brandsApi.get(BRANDS_URL + queryString);
  const parsedData = parseData(BrandsResponseSchema, data);
  return parsedData.product_brands;
};

export const useBrands = createUseQueryHook<FetchBrandsParams, Brand[]>(
  fetchBrands,
  queryKeys.brands,
  (params) => !!params.productBrandIds
);

export const useInfiniteBrands = ({
  enabled,
  ...params
}: UseInfiniteBrandsParams) => {
  const brandsQuery = useInfiniteQuery({
    enabled,
    queryFn: async ({ pageParam = 0 }) => {
      const brands = await fetchBrands({
        ...params,
        limit: params.perPage,
        offset: pageParam * params.perPage,
      });

      return { brands, pageParam };
    },
    queryKey: queryKeys.infiniteBrands(params),
    getNextPageParam: (lastPage) => {
      const isLastPage = (lastPage.brands || []).length < params.perPage;
      return isLastPage ? undefined : lastPage.pageParam + 1;
    },
  });

  let brands = undefined;

  if (brandsQuery.data) {
    const { pages } = brandsQuery.data;
    brands = pages.flatMap((body) => body.brands);
  }

  return { ...brandsQuery, brands };
};
