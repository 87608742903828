import type {
  FetchBrandSpecialParams,
  FetchBrandSpecialsParams,
} from './brandSpecials.types';

export const queryKeys = {
  brandSpecial: (params: FetchBrandSpecialParams) => [
    'brandSpecial',
    params.id,
  ],
  brandSpecials: (params: FetchBrandSpecialsParams) => [
    'brandSpecials',
    params,
  ],
  infiniteBrandSpecials: (
    params: FetchBrandSpecialsParams & { perPage: number }
  ) => ['infiniteBrandSpecials', params],
};
