import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AdBuilder,
  buildDuplicateFlightDetailsFormData,
  useAdBuilderForm,
  useAdBuilderPrefill,
} from '@jane/ad-manager/components';
import { useGetFlightDetail } from '@jane/ad-manager/data-access';
import { useToast } from '@jane/shared/reefer';

export const AdBuilderPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [duplicatedFlightId, setDuplicatedFlightId] = useState<number>();

  const adBuilderForm = useAdBuilderForm({
    onError: () =>
      toast.add({ label: 'Failed to create flight', variant: 'error' }),
    onSuccess: () => {
      toast.add({ label: 'Flight created successfully' });
      navigate('/digital-merchandising');
    },
  });

  const { data: getFlightResult, isLoading: isFlightLoading } =
    useGetFlightDetail({
      params:
        duplicatedFlightId === undefined
          ? undefined
          : { flightId: duplicatedFlightId },
    });
  useEffect(() => {
    if (getFlightResult === undefined || isFlightLoading) return;

    adBuilderForm.formMethods.reset(
      buildDuplicateFlightDetailsFormData(getFlightResult)
    );
  }, [getFlightResult, isFlightLoading]);

  useAdBuilderPrefill((prefill) => {
    if (typeof prefill !== 'number') return;

    setDuplicatedFlightId(prefill);
  });

  return (
    <AdBuilder
      adBuilderForm={adBuilderForm}
      onDismiss={() => navigate('/digital-merchandising')}
    />
  );
};
