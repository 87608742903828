import { useState } from 'react';

import type { CategoryOption } from '@jane/shared-b2b/components';
import {
  Banner,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Form,
  InfoIcon,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { sentenceCase } from '@jane/shared/util';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { CategorySelection } from './categorySelection';
import { ProductSelection } from './productSelection';
import { mockProducts } from './selectedProducts';
import { WeightSelection } from './weightSelection';

export interface ProductSectionProps {
  categories: CategoryOption[];
  weights: string[];
}
export type BrandSpecialRulesVariant = 'inclusion' | 'exclusion';

const BrandSpecialRules = ({
  categories,
  variant,
  weights,
}: {
  categories: CategoryOption[];
  variant: BrandSpecialRulesVariant;
  weights: string[];
}) => {
  const isInclusion = variant === 'inclusion';
  const defaultRadioValue = isInclusion ? 'all' : 'none';
  const defaultLabel = isInclusion ? 'All products' : 'No exclusions';
  const bannerLabel = isInclusion
    ? 'Discounted products must satisfy all inclusion rules set'
    : 'Excluded products must satisfy all exclusion rules set';

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultRadioValue);

  return (
    <>
      <SpecialFormBlock first={isInclusion}>
        {isInclusion && (
          <Typography variant="header-bold" branded mb={32}>
            Products
          </Typography>
        )}
        <Flex
          flexDirection="row"
          width="100%"
          alignItems="center"
          mb={open ? 24 : 0}
          onClick={() => setOpen((prev) => !prev)}
          aria-label={`${open ? 'Close' : 'Open'} ${variant}s section`}
        >
          <Flex flexDirection="column" mb={open ? 24 : 0}>
            <Typography variant="body-bold">
              {`${sentenceCase(variant)}s`}
            </Typography>
            {!open && (
              <Typography>
                {selected === 'specific'
                  ? 'Specific products, category and weight'
                  : defaultLabel}
              </Typography>
            )}
          </Flex>
          {open ? <ChevronUpIcon ml="auto" /> : <ChevronDownIcon ml="auto" />}
        </Flex>
        {open && (
          <>
            <Form.RadioFieldGroup
              name={variant}
              options={[
                {
                  id: `${variant}-${defaultRadioValue}`,
                  label: defaultLabel,
                  value: `${defaultRadioValue}`,
                },
                {
                  id: `${variant}-specific`,
                  label: 'Specific products, category and weight',
                  value: 'specific',
                },
              ]}
              onChange={setSelected}
              defaultChecked={selected}
            />
            <Banner
              full
              icon={<InfoIcon />}
              label={<Typography>{bannerLabel}</Typography>}
            />
          </>
        )}
      </SpecialFormBlock>

      {selected === 'specific' && (
        <SpecialFormBlock>
          <ProductSelection
            filters={{
              category: ['flower', 'edible'],
              lineage: ['indica', 'sativa'],
            }}
            selectedFilters={undefined}
            selectedProducts={mockProducts}
            variant={variant}
          />

          <Modal.ContentDivider color="grays-light" padding={false} />

          <CategorySelection categories={categories} variant={variant} />

          <Modal.ContentDivider color="grays-light" padding={false} />

          <WeightSelection weights={weights} />
        </SpecialFormBlock>
      )}
    </>
  );
};

export const ProductsSection = ({
  categories,
  weights,
}: ProductSectionProps) => {
  return (
    <Card width="100%" style={{ overflow: 'visible' }}>
      <StyledCardGroup width="100%">
        <BrandSpecialRules
          variant="inclusion"
          weights={weights}
          categories={categories}
        />
        <BrandSpecialRules
          variant="exclusion"
          weights={weights}
          categories={categories}
        />
      </StyledCardGroup>
    </Card>
  );
};
