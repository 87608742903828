import styled from '@emotion/styled';
import type { Column, ColumnMeta } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import type { NavigateFunction } from 'react-router-dom';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import {
  formatCurrency,
  formatDatepickerString,
  getFlightStatus,
  parseUTCDateTimeInPT,
} from '@jane/ad-manager/util';
import { brandsPaths } from '@jane/brands/util';
import {
  Box,
  Button,
  Flex,
  Link,
  List,
  Loading,
  MoreIcon,
  Popover,
  SortUpIcon,
  Typography,
} from '@jane/shared/reefer';

import type { RowActions } from './useFlightsTable';

export interface CustomColumnMeta<TData, TValue>
  extends ColumnMeta<TData, TValue> {
  headerLabel?: string;
}

const columnHelper = createColumnHelper<ApiFlightSummary>();

const StyledPopover = styled(Popover)`
  & #popover-content {
    margin-top: 4px;
    margin-left: 14px;
  }
`;

type cellContentFormatterParams = {
  contentNamePlural: string;
  foldingThreshold: number;
  withPopover?: boolean;
};

const cellContentFormatter = (
  content: string,
  params: cellContentFormatterParams
) => {
  if (!content) {
    return <Typography color="grays-mid">None</Typography>;
  }

  const contentArray = content.split(',');
  if (contentArray.length === 0) return null;
  if (contentArray.length < params.foldingThreshold) {
    return contentArray.join(', ');
  }

  if (params.withPopover) {
    return (
      <StyledPopover
        target={
          <div>
            {contentArray.length} {params.contentNamePlural}
          </div>
        }
        label="states"
        openOn="hover"
        variant="info"
        noMinWidth={true}
        closeOnTargetClick={false}
      >
        <Popover.Content>
          <Typography>{contentArray.join(', ')}</Typography>
        </Popover.Content>
      </StyledPopover>
    );
  }

  return (
    <div>
      {contentArray.length} {params.contentNamePlural}
    </div>
  );
};

const dateStringInPTFromISODate = (isoDateString?: string | null) => {
  if (!isoDateString) return 'Auto';

  return formatDatepickerString(parseUTCDateTimeInPT(isoDateString));
};

const SortableHeader = ({
  column,
  label,
}: {
  column: Column<ApiFlightSummary, unknown>;
  label: string;
}) => {
  const isColumnSorted =
    column.getIsSorted() === 'asc' || column.getIsSorted() === 'desc';
  const iconColor = isColumnSorted ? 'primary-dark' : 'primary-light';
  const handleSortingToggle = () => {
    const isSorted = column.getIsSorted();
    column.toggleSorting(isSorted === 'asc');
  };

  return (
    <Link variant="minimal" onClick={handleSortingToggle}>
      <Flex alignItems="center">
        <Typography variant="caps">{label}</Typography>
        <SortUpIcon
          color={iconColor}
          rotate={column.getIsSorted() === 'asc' ? 'up' : 'down'}
        />
      </Flex>
    </Link>
  );
};

export const getColumns = ({
  onDuplicateRow,
  onSetActive,
  loadingFlightIds,
  navigate,
}: RowActions & {
  loadingFlightIds: number[];
  navigate: NavigateFunction;
}) => [
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      const isLoading = loadingFlightIds.indexOf(row.original.id) !== -1;

      if (isLoading)
        return (
          <Box pl={24} pt={8}>
            <Loading style={{ position: 'static' }} size="xs"></Loading>
          </Box>
        );

      const isReadOnly = !row.original.active && row.original.readOnly;

      return (
        <StyledPopover
          label="row-actions"
          target={
            <Button.Icon label="row-actions-button" icon={<MoreIcon />} />
          }
        >
          <Popover.Content label="row-actions-content">
            <List label="row-actions-list">
              <List.Item>
                <Link
                  variant="minimal"
                  onClick={() =>
                    navigate(brandsPaths.flightDetails(row.original.id))
                  }
                >
                  View detail
                </Link>
              </List.Item>
              <List.Item>
                <Link
                  variant="minimal"
                  onClick={() => onDuplicateRow(row)}
                  ariaLabel="duplicate flight"
                >
                  Duplicate
                </Link>
              </List.Item>
              <List.Item>
                <Link
                  onClick={() => {
                    if (!isReadOnly) {
                      onSetActive(row.original.id, !row.original.active);
                    }
                  }}
                  variant="minimal"
                  ariaLabel={row.original.active ? 'Deactivate' : 'Activate'}
                  data-testid={
                    isReadOnly
                      ? 'read-only'
                      : row.original.active
                      ? 'deactivate'
                      : 'activate'
                  }
                  color={isReadOnly ? 'grays-light' : 'grays-black'}
                  style={{ cursor: isReadOnly ? 'not-allowed' : 'pointer' }}
                >
                  {row.original.active ? 'Deactivate' : 'Activate'}
                </Link>
              </List.Item>
            </List>
          </Popover.Content>
        </StyledPopover>
      );
    },
  }),

  // status
  columnHelper.accessor(
    (row) =>
      getFlightStatus({
        active: row.active,
        endDate: row.endDate,
        readOnly: row.readOnly,
        startDate: row.startDate,
      }),
    {
      id: 'status',
      header: ({ column }) => <SortableHeader label="status" column={column} />,
      meta: {
        headerLabel: 'Status',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }
  ),

  // id
  columnHelper.accessor(({ id }) => id.toString(), {
    id: 'id',
    header: ({ column }) => <SortableHeader label="flight" column={column} />,
  }),

  // states
  columnHelper.accessor(({ states }) => states?.sort().toString(), {
    id: 'states',
    header: () => <Typography variant="caps">states</Typography>,
    cell: ({ getValue }) =>
      cellContentFormatter(getValue(), {
        contentNamePlural: 'states',
        foldingThreshold: 4,
        withPopover: true,
      }),
    meta: {
      headerLabel: 'States',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // stores
  columnHelper.accessor(
    ({ stores = [] }) =>
      stores
        .map((s) => s.id)
        .sort((a, b) => a - b)
        .toString(),
    {
      id: 'stores',
      header: () => <Typography variant="caps">stores</Typography>,
      cell: ({ getValue }) =>
        cellContentFormatter(getValue(), {
          contentNamePlural: 'stores',
          foldingThreshold: 2,
        }),
      meta: {
        headerLabel: 'Stores',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }
  ),

  // starts
  columnHelper.accessor('startDate', {
    id: 'startDate',
    header: ({ column }) => <SortableHeader label="starts" column={column} />,
    cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
  }),

  // ends
  columnHelper.accessor('endDate', {
    id: 'endDate',
    header: ({ column }) => <SortableHeader column={column} label="ends" />,
    cell: ({ getValue }) => dateStringInPTFromISODate(getValue()),
  }),

  // type
  columnHelper.accessor('model', {
    id: 'model',
    header: ({ column }) => <SortableHeader label="type" column={column} />,
    cell: ({ getValue }) => getValue().toUpperCase(),
    meta: {
      headerLabel: 'Type',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // products
  columnHelper.accessor(
    ({ selectedProducts = [] }) =>
      selectedProducts
        .map((sp) => sp.id)
        .sort((a, b) => a - b)
        .toString(),
    {
      id: 'products',
      cell: ({ getValue }) =>
        cellContentFormatter(getValue(), {
          contentNamePlural: 'products',
          foldingThreshold: 2,
        }),
      header: () => <Typography variant="caps">products</Typography>,
      meta: {
        headerLabel: 'Products',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }
  ),

  // placement
  columnHelper.accessor(({ zones }) => zones?.sort().toString(), {
    id: 'placement',
    header: () => <Typography variant="caps">placement</Typography>,
    cell: ({ getValue }) =>
      cellContentFormatter(getValue(), {
        contentNamePlural: 'placements',
        foldingThreshold: 2,
        withPopover: true,
      }),
    meta: {
      headerLabel: 'Placement',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // daily budget
  columnHelper.accessor('dailyBudget', {
    id: 'dailyBudget',
    header: ({ column }) => (
      <SortableHeader label="daily budget" column={column} />
    ),
    cell: ({ getValue }) => {
      const value = getValue();

      if (value !== null) {
        return `${formatCurrency(value)}`;
      }

      return <Typography color="grays-mid">Not set</Typography>;
    },
    meta: {
      headerLabel: 'Daily budget',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // spend
  columnHelper.accessor('spend', {
    id: 'spend',
    header: ({ column }) => <SortableHeader label="spend" column={column} />,
    cell: ({ getValue }) =>
      getValue() ? (
        `${formatCurrency(getValue())}`
      ) : (
        <Typography color="grays-mid">Not set</Typography>
      ),
    meta: {
      headerLabel: 'Spend',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // bid
  columnHelper.accessor('bid', {
    id: 'bid',
    header: ({ column }) => <SortableHeader label="bid" column={column} />,
    cell: ({ getValue }) => {
      const value = getValue();

      if (value !== null) {
        return `${formatCurrency(value)}`;
      }

      return <Typography color="grays-mid">Not set</Typography>;
    },
    meta: {
      headerLabel: 'Bid',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // frequency cap
  columnHelper.accessor(
    ({ frequencyCapUnit, frequencyCapDenominator, frequencyCapNumerator }) =>
      frequencyCapUnit && frequencyCapUnit !== 'disabled'
        ? `${frequencyCapNumerator}/${frequencyCapDenominator} ${frequencyCapUnit}`
        : undefined,
    {
      id: 'frequencyCap',
      header: () => <Typography variant="caps">frequency cap</Typography>,
      cell: ({ getValue }) =>
        getValue() ?? <Typography color="grays-mid">Not set</Typography>,
      meta: {
        headerLabel: 'Frequency cap',
      } as CustomColumnMeta<ApiFlightSummary, string>,
    }
  ),

  // impressions
  columnHelper.accessor((row) => row.impressions.toString(), {
    id: 'impressions',
    header: ({ column }) => (
      <SortableHeader label="impressions" column={column} />
    ),
    meta: {
      headerLabel: 'Impressions',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),

  // displays on
  columnHelper.accessor('displaysOn', {
    id: 'displaysOn',
    header: ({ column }) => (
      <SortableHeader label="displays on" column={column} />
    ),
    meta: {
      headerLabel: 'Displays on',
    } as CustomColumnMeta<ApiFlightSummary, string>,
  }),
];
