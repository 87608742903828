/* eslint-disable-next-line no-restricted-imports */
import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk';

export type { FlagsType } from './lib/useFlags';

export * from './lib/eventNames';
export * from './lib/flagContext';
export * from './lib/flagProvider';
export * from './lib/flags';

export { useFlag, useFlags } from './lib/useFlags';
export const useFlagClient = useLDClient;
export const useFlagClientError = useLDClientError;
