import styled from '@emotion/styled';
import type { ForwardedRef } from 'react';

import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_DESKTOP_GRID_GAP,
  DEFAULT_MOBILE_CARD_WIDTH,
  DEFAULT_MOBILE_GRID_GAP,
} from '@jane/shared/components';
import { Box } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledGrid = styled(Box)<{
  listView: boolean;
  ref?: ForwardedRef<HTMLElement>;
}>(({ listView }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '24px',
  padding: '1px', // prevents card borders from being cut off
  ...(!listView && {
    display: 'grid',
    gap: `24px ${DEFAULT_MOBILE_GRID_GAP}px`,
    justifyContent: 'flex-start',
    gridAutoRows: '1fr',
    gridAutoFlow: 'row dense',
    overflowX: 'auto',
    gridTemplateColumns: `repeat(2, minmax(${DEFAULT_MOBILE_CARD_WIDTH}px, 1fr))`,
    [mediaQueries.tablet('min')]: {
      gap: `24px ${DEFAULT_DESKTOP_GRID_GAP}px`,
      gridTemplateColumns: `repeat(auto-fill, minmax(${DEFAULT_DESKTOP_CARD_WIDTH}px, 1fr))`,
      height: '100%',
    },
  }),
}));
