import { useSelectedSubdivision } from '@jane/catalog-cms/hooks';
import { LOCALIZATION_OPTIONS } from '@jane/catalog-cms/util';
import { SelectField } from '@jane/shared/reefer';

export const StateSelector = () => {
  const { selectedSubdivision, updateSelectedSubdivision } =
    useSelectedSubdivision();

  return (
    <SelectField
      onChange={(value) => updateSelectedSubdivision(value)}
      name="subdivision"
      options={LOCALIZATION_OPTIONS}
      label="View catalog in state"
      placeholder="Default"
      defaultValue={selectedSubdivision}
    />
  );
};
