import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';

import type { PriceId, WeightSelectorWeight } from '@jane/shared/models';
import { Box, Button, Flex } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import {
  canWeightBeAddedToCart,
  filterWeightsAboveMax,
  formatCurrency,
  getMaxCartQuantity,
  productPhotoMetadata,
} from '@jane/shared/util';

import { ProductImage } from '../ProductImage';
import { DisplayMode, useProductCardContext } from '../productCardProvider';
import { QuantitySelector } from '../quantitySelector/quantitySelector';
import { WeightPriceItems } from '../weightSelector/weightPriceItems';
import { MenuProductCardBackButton } from './backButton';
import { MenuBasicProductInfo } from './menuProductInfo';

export const ProductPhotoCorner = styled.div(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  borderTop: '120px solid transparent',
  borderLeft: `100px solid ${theme.colors.grays.ultralight}`,
  width: 0,
}));

const ProductPhoto = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 100,
  overflow: 'hidden',
  height: 100,
  img: {
    objectFit: 'cover',
  },
});

const OverflowGrid = styled.div<{ moreThanTwoWeights: boolean }>(
  ({ moreThanTwoWeights, theme }) => ({
    '> div': {
      ...spacing({ pb: 12 }),
      display: 'grid',
      justifyContent: moreThanTwoWeights ? 'left' : 'center',
      gridTemplateRows: '54px 54px',
      gridAutoFlow: moreThanTwoWeights ? 'column' : 'row',
      gridAutoColumns: 88,
      gridGap: 4,
      overflowX: 'scroll',
      scrollbarColor: `gray transparent`,
      '&::-webkit-scrollbar': {
        backgroundColor: `transparent`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme.colors.grays.mid}`,
        borderRadius: 100,
      },
    },
  })
);

const WeightSelectorLabel = styled.label({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
});

interface Props {
  decrementQuantity: () => void;
  discountedPrice?: number;
  height?: number | string;
  incrementQuantity: () => void;
  maxWeightOption?: number | null;
  multipleCountDisabled: boolean;
  onAddToCartPressed: () => void;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice?: number;
  selectedQuantity: number;
  shoppingDisabled: boolean;
  showOnlyWeights?: string[] | null;
  soldByWeight: boolean;
  weights: WeightSelectorWeight[];
}

export const MenuProductCardEditView = ({
  decrementQuantity,
  discountedPrice,
  height,
  incrementQuantity,
  multipleCountDisabled,
  onAddToCartPressed,
  onWeightSelected,
  originalPrice,
  selectedQuantity,
  shoppingDisabled,
  showOnlyWeights = null,
  soldByWeight,
  weights,
  maxWeightOption,
}: Props) => {
  const {
    appMode,
    cartProduct,
    currentSpecial,
    menuProduct,
    selectedWeight,
    setDisplayMode,
    weightFilter,
  } = useProductCardContext();

  const price =
    currentSpecial && discountedPrice ? discountedPrice : originalPrice;

  // The new specialWeights function already takes showOnlyWeights into account.
  const moreThanTwoWeights =
    weightFilter || !showOnlyWeights?.length
      ? weights.length > 2
      : showOnlyWeights.length > 2;

  const photoMetadata = productPhotoMetadata(menuProduct);

  const weightInCart = (weight: PriceId) =>
    cartProduct?.find((product) => product.price_id === weight);

  const selectedWeightInCart = weightInCart(selectedWeight);

  const buttonText = shoppingDisabled
    ? 'Shopping disabled'
    : cartProduct && selectedQuantity === 0
    ? 'Remove'
    : cartProduct && selectedWeightInCart
    ? 'Update'
    : soldByWeight && !selectedWeight
    ? 'Select a weight'
    : 'Confirm';

  const weightSelectionLabelId = 'weight-selection-' + menuProduct?.id;

  const hasMaxWeightOption = !isNil(maxWeightOption);

  const filteredWeights: WeightSelectorWeight[] = useMemo(() => {
    // the new specialWeights function happens only once, in the provider
    // it has already taken bulk weights into account.
    if (weightFilter) return weights;

    let oldFilteredWeights = isNil(maxWeightOption)
      ? weights
      : filterWeightsAboveMax(weights, cartProduct, maxWeightOption);

    if (!isEmpty(showOnlyWeights) && !isNil(showOnlyWeights)) {
      oldFilteredWeights = oldFilteredWeights.filter(
        (weight) =>
          showOnlyWeights.includes(weight.value) || weightInCart(weight.value)
      );
    }

    return oldFilteredWeights;
  }, [cartProduct, showOnlyWeights, maxWeightOption, weights]);

  const weightAlreadyInCart = selectedWeight === selectedWeightInCart?.price_id;
  const weightAndOptionAlreadyInCart =
    weightAlreadyInCart && selectedQuantity === selectedWeightInCart?.count;

  let selectedWeightIsOverMaxWeight = false;

  let canAddMore = true;

  if (hasMaxWeightOption && maxWeightOption) {
    const weightStatus = canWeightBeAddedToCart(
      selectedWeight,
      cartProduct,
      selectedQuantity,
      maxWeightOption
    );

    selectedWeightIsOverMaxWeight = !weightStatus['canBeAddedToCart'];
    canAddMore = weightStatus['canAddMore'];
  }

  const minQuantity =
    cartProduct && (selectedWeightInCart?.count || 0) > 0 ? 0 : 1;

  return (
    <Flex flexDirection="column" justifyContent="space-between" height={height}>
      <Box px={8} pt={8}>
        <MenuProductCardBackButton
          onClick={() => {
            setDisplayMode(DisplayMode.Product);
          }}
        />
        <Box pb={8} position="relative" zIndex={1}>
          <MenuBasicProductInfo
            applyTruncateOffset
            fullDetails={false}
            selectedWeight={selectedWeight}
          />
        </Box>

        <ProductPhoto>
          <ProductPhotoCorner />
          <ProductImage src={photoMetadata.url} alt={menuProduct?.name || ''} />
        </ProductPhoto>
      </Box>
      <Box>
        {soldByWeight && (
          <OverflowGrid moreThanTwoWeights={moreThanTwoWeights}>
            <WeightSelectorLabel id={weightSelectionLabelId}>
              Select Package Weight
            </WeightSelectorLabel>
            <WeightPriceItems
              labelId={weightSelectionLabelId}
              onChange={onWeightSelected}
              selectedWeight={selectedWeight}
              weights={filteredWeights}
            />
          </OverflowGrid>
        )}
      </Box>
      <Flex justifyContent="center" px={8}>
        {!multipleCountDisabled && (
          <QuantitySelector
            appMode={appMode}
            maxQuantity={
              canAddMore && menuProduct
                ? getMaxCartQuantity(menuProduct, selectedWeight)
                : selectedQuantity
            }
            minQuantity={minQuantity}
            multipleCountDisabled={multipleCountDisabled}
            value={selectedQuantity ?? 1}
            incrementQuantity={incrementQuantity}
            decrementQuantity={decrementQuantity}
          />
        )}
      </Flex>
      <Flex px={4} mx={8} mb={16}>
        <Button
          full
          label={
            selectedQuantity > 0
              ? `${buttonText} ${formatCurrency(price)}`
              : buttonText
          }
          aria-label={buttonText.toLowerCase()}
          disabled={
            selectedWeightIsOverMaxWeight ||
            shoppingDisabled ||
            (soldByWeight && !selectedWeight) ||
            weightAndOptionAlreadyInCart
          }
          onClick={onAddToCartPressed}
        />
      </Flex>
    </Flex>
  );
};
