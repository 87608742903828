import type { Column } from '@tanstack/react-table';

import {
  Button,
  CheckboxField,
  ChevronDownIcon,
  Flex,
  Link,
  Popover,
} from '@jane/shared/reefer';
import { sentenceCase } from '@jane/shared/util';

import { ActiveFilterTag } from '../activeFilterTag/activeFilterTag';
import {
  StyledFilterList,
  StyledFilterListButton,
  StyledFilterListCheckboxField,
  StyledFilterListItem,
} from '../filter.styles';

export const ProductSelectFilter = <R,>({
  column,
  filterOptions,
}: {
  column: Column<R>;
  filterOptions?: string[];
}) => {
  const activeFilters = (column.getFilterValue() ?? []) as string[];
  const filterLabel = sentenceCase(column.id);

  const onFilterChange = (option: string) => {
    let newFilterValues: string[];

    if (activeFilters.includes(option)) {
      newFilterValues = activeFilters.filter((filter) => filter !== option);
    } else {
      newFilterValues = [...activeFilters, option];
    }
    column.setFilterValue(newFilterValues);
  };

  if (!filterOptions || !filterOptions?.length) {
    return null;
  }

  return (
    <Popover
      label={`Filters for ${filterLabel}`}
      target={
        <Button
          label={filterLabel}
          variant={activeFilters.length ? 'tertiary-selected' : 'tertiary'}
          endIcon={
            <Flex alignItems="center">
              <ActiveFilterTag
                valuesCount={activeFilters.length}
                label={filterLabel}
              />
              <ChevronDownIcon color="inherit" size="sm" />
            </Flex>
          }
        />
      }
    >
      {({ closePopover }) => (
        <Popover.Content padding={false}>
          <StyledFilterList aria-label={`${filterLabel} filter options`}>
            {filterOptions.map((option, index) => (
              <StyledFilterListItem key={index}>
                <StyledFilterListCheckboxField>
                  <CheckboxField
                    label={sentenceCase(option)}
                    name={`${option}-checkbox`}
                    onClick={() => onFilterChange(option)}
                    checked={activeFilters.includes(option)}
                  />
                </StyledFilterListCheckboxField>
              </StyledFilterListItem>
            ))}
            <StyledFilterListItem>
              <StyledFilterListButton
                onClick={() => {
                  column.setFilterValue([]);
                  closePopover();
                }}
              >
                <Link typography="body">Deselect all</Link>
              </StyledFilterListButton>
            </StyledFilterListItem>
          </StyledFilterList>
        </Popover.Content>
      )}
    </Popover>
  );
};
