import { createContext, useContext, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { isSortedByPrice } from '@jane/search/util';
import type {
  AppMode,
  Brand,
  DeepReadonly,
  Id,
  MenuProduct,
  PriceId,
  Product,
  ReservationCartProduct,
  RouteAppMode,
  Store,
  StoreSpecial,
  UserLocation,
} from '@jane/shared/models';
import type {
  AlmostGone,
  Breadcrumb,
  EcommPaths,
  MenuProduct as ZMenuProduct,
} from '@jane/shared/types';
import type { PartnerHostedConfig } from '@jane/shared/util';
import {
  defaultWeightIdForMenuProduct,
  defaultWeightIdForMenuProductFromFilters,
  findDefaultWeightForKind,
  isSoldByWeight,
} from '@jane/shared/util';

import { firstAvailablePriceIdFromFilters } from './legacyMenuProductCard/utils/firstAvailablePriceIdFromFilters';

export enum DisplayMode {
  Confirmation = 'confirmation',
  Edit = 'edit',
  Product = 'product',
}

export type AddData = {
  almostGone?: AlmostGone;
  count: number;
  location: 'menu' | 'productDetailPage' | 'pendingCart' | 'marketplaceProduct';
  menuProduct: MenuProduct | ZMenuProduct;
  price_id: PriceId;
  special?: StoreSpecial;
  store: DeepReadonly<Store>;
};
export type DeleteData = {
  itemId: number;
  selectedWeight: PriceId | null;
};

export interface ProductCardContextProps {
  appMode: AppMode;
  appliedWeightFilter: PriceId | '';
  availableWeights?: PriceId[] | null;
  brand?: Brand;
  breadcrumbs?: Breadcrumb[];
  carouselView?: boolean;
  cartIsOpen: boolean;
  cartProduct?: ReservationCartProduct[];
  currentSpecial?: StoreSpecial;
  customerId: number | null;
  defaultWeight: PriceId;
  disableInteraction?: boolean;
  displayMode: DisplayMode;
  fromAllSpecials?: boolean | null;
  fromSpecialId?: Id | null;
  hideActions?: boolean;
  janeGoldLabel?: string | null;
  listView?: boolean;
  maxWeightOption?: number | null;
  menuProduct?: MenuProduct;
  onAddToCart?: (addData: AddData) => void;
  onCloseCart?: () => void;
  onDeleteFromCart?: (deleteData: DeleteData) => void;
  onSetBreadcrumbs?: (searchState: JaneSearchState<AlgoliaProduct>) => void;
  pdpPath?: EcommPaths['menuProduct'];
  product?: Product;
  routeAppMode?: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  searchState: JaneSearchState<AlgoliaProduct>;
  selectedWeight: PriceId;
  setDisplayMode: (arg: DisplayMode) => void;
  setSelectedWeight: (arg: PriceId) => void;
  showOnlyWeights?: string[] | null;
  store?: DeepReadonly<Store>;
  trackListViewClick?: () => void;
  userLocation: UserLocation | null;
  weightFilter?: (
    weight: string,
    product: ZMenuProduct | AlgoliaProduct
  ) => boolean;
}

const defaultContext: ProductCardContextProps = {
  appMode: 'default',
  appliedWeightFilter: '',
  carouselView: false,
  cartIsOpen: false,
  cartProduct: [],
  customerId: null,
  defaultWeight: 'gram',
  displayMode: DisplayMode.Product,
  listView: false,
  routePartnerHostedConfig: { isPartnerHosted: false },
  searchState: {},
  selectedWeight: 'gram',
  setDisplayMode: () => {
    /**   */
  },
  setSelectedWeight: () => {
    /**   */
  },
  userLocation: null,
};

export const ProductCardContext =
  createContext<ProductCardContextProps>(defaultContext);

export const ProductCardProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: Partial<ProductCardContextProps>;
}) => {
  const [displayMode, setDisplayMode] = useState(DisplayMode.Product);

  // If using the new specialWeights functions, we take all variables into account
  // and determine the available_weights here to be consumed by other components.
  const availableWeights = useMemo(() => {
    if (value.menuProduct && value.weightFilter) {
      if (!isSoldByWeight(value.menuProduct.kind)) {
        return [];
      }

      const weights =
        value.menuProduct.available_weights?.map((weight) =>
          weight.replace(' ', '_')
        ) ?? [];

      const availableWeights = weights?.filter(
        (weight) =>
          !value.weightFilter ||
          value.weightFilter(weight, value.menuProduct as ZMenuProduct)
      );

      return availableWeights as PriceId[];
    }
    return null;
  }, [value.menuProduct, value.weightFilter, value.showOnlyWeights]);

  // This value is consumed by the listViewContainer to determine which weight is autoselected.
  // If the above availableWeights returns a value, we use that array as the starting source of truth.
  const defaultWeight: PriceId | null = useMemo(() => {
    if (availableWeights) {
      if (!availableWeights?.length) return 'each';
      if (value?.cartProduct?.length) return value.cartProduct[0].price_id;

      const defaultWeightByKind =
        isSortedByPrice(value.searchState) ||
        value.searchState?.filters?.available_weights?.length
          ? availableWeights[0] ??
            findDefaultWeightForKind((value.menuProduct as MenuProduct).kind)
          : findDefaultWeightForKind((value.menuProduct as MenuProduct).kind) ??
            availableWeights[0];

      const defaultWeight = availableWeights.includes(defaultWeightByKind)
        ? defaultWeightByKind
        : availableWeights[0];

      if (value.searchState?.filters?.available_weights) {
        return firstAvailablePriceIdFromFilters({
          menuProduct: value.menuProduct as MenuProduct,
          appliedFilters: value.searchState.filters
            .available_weights as string[],
          defaultValue: defaultWeight,
        });
      }

      return defaultWeight;
    } else {
      const weightFilters = value?.searchState?.filters?.available_weights;
      return weightFilters && weightFilters.length > 1
        ? defaultWeightIdForMenuProductFromFilters({
            appliedWeightFilters: weightFilters as string[],
            menuProduct: value.menuProduct as MenuProduct,
          })
        : defaultWeightIdForMenuProduct({
            menuProduct: value.menuProduct as MenuProduct,
            special: value.currentSpecial,
            appliedWeightFilter: value.appliedWeightFilter,
            sortedByPrice: isSortedByPrice(value.searchState),
            showOnlyWeights: value.showOnlyWeights,
          });
    }
  }, [
    availableWeights,
    value.appliedWeightFilter,
    value.cartProduct,
    value.currentSpecial,
    value.searchState,
  ]);

  const providerValue = useMemo(
    () => ({
      ...defaultContext,
      ...value,
      availableWeights,
      defaultWeight,
      displayMode: value.displayMode || displayMode,
      setDisplayMode,
    }),
    [availableWeights, displayMode, defaultWeight, value, setDisplayMode]
  );

  return (
    <ProductCardContext.Provider value={providerValue}>
      {children}
    </ProductCardContext.Provider>
  );
};

export const useProductCardContext = () => {
  const context = useContext(ProductCardContext);

  if (context === undefined) {
    throw new Error(
      'useProductCardContext must be used within a ProductCardProvider'
    );
  }

  return context;
};
