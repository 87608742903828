import type { ApiFlightDetails } from '@jane/ad-manager/data-access';
import { scheduleForWeek } from '@jane/ad-manager/data-access';
import type { DayPart, Weekday } from '@jane/ad-manager/types';
import { formatISODate, parsePacificTime } from '@jane/ad-manager/util';

import { transformBidModifiersFromApi } from './AdBuilder/Pricing/BidModifiers';
import {
  ORDERED_DAYS,
  WEEKDAY_TO_WEEKDAY_MIN,
} from './AdBuilder/Scheduling/constants';
import { PricingDetails } from './FlightDetails/PricingDetails';
import { ProductDetails } from './FlightDetails/ProductDetails';
import { ScheduleDetails } from './FlightDetails/ScheduleDetails';
import { TargetingDetails } from './FlightDetails/TargetingDetails';
import { TypeDetails } from './FlightDetails/TypeDetails';

export const FlightSubmissionDetails = ({
  flight,
}: {
  flight: ApiFlightDetails;
}) => {
  const bidModifiers = transformBidModifiersFromApi(flight.bidModifiers);

  const storeDisplayFromEntry = ({
    description,
    id,
  }: {
    description: string;
    id: number;
  }) => ({
    id: id.toString(),
    name: description,
  });

  const dayParts = flight.schedule;
  const schedule = scheduleForWeek(dayParts);
  const timeFormat = 'h:mm a';

  const START_OF_DAY = '00:00:00';
  const END_OF_DAY = '23:59:00';

  const isDayPartAllDay = (dayPart: DayPart) =>
    dayPart.startTime === START_OF_DAY &&
    (dayPart.endTime === START_OF_DAY || dayPart.endTime === END_OF_DAY);

  const manualDays =
    dayParts && dayParts.length > 0
      ? ORDERED_DAYS.map((dayName) => {
          // start with monday, end with sunday
          const dayAbbreviation = WEEKDAY_TO_WEEKDAY_MIN[dayName];
          const scheduleForDay = schedule[dayAbbreviation];
          if (!scheduleForDay) {
            return undefined;
          }
          if (isDayPartAllDay(scheduleForDay)) {
            return { name: dayName, value: 'All day' };
          }
          return {
            name: dayName,
            value: `${parsePacificTime(scheduleForDay.startTime).format(
              timeFormat
            )} - ${parsePacificTime(scheduleForDay.endTime).format(
              timeFormat
            )}`,
          };
        }).filter((item): item is { name: Weekday; value: string } => !!item)
      : undefined;

  return (
    <>
      <TypeDetails
        placements={flight.zones}
        displaysOn={flight.displaysOn || ''}
        model={flight.model}
        menuRowTitle={flight.title || undefined}
      />
      <ProductDetails
        {...(flight.keywords && flight.keywords.length > 0
          ? {
              mode: 'category',
              categories: flight.keywords.map((keyword) => keyword.name),
            }
          : flight.selectedProducts && flight.selectedProducts.length > 0
          ? {
              mode: 'manual',
              productNames: flight.selectedProducts.map(({ name }) => name),
            }
          : { mode: 'auto' })}
      />
      {flight.priority === 'premium' && (
        <>
          <PricingDetails
            priority
            totalBudget={`$${flight.bid}`}
            bidModifiers={bidModifiers}
            creditPercent={flight.creditPercent || 0}
          />
          <TargetingDetails
            priorityEnabled
            stores={flight.stores.map(storeDisplayFromEntry)}
          />
        </>
      )}
      {flight.priority === 'auction' && (
        <>
          <PricingDetails
            dailyBudget={`$${flight.dailyBudget}`}
            cpmBid={`$${flight.bid}`}
            bidModifiers={bidModifiers}
            creditPercent={flight.creditPercent || 0}
          />
          <TargetingDetails
            segmentCategory={flight.segment?.description || ''}
            states={flight.states || []}
            stores={flight.stores.map(storeDisplayFromEntry)}
            storesExcluded={flight.excludedStores.map(storeDisplayFromEntry)}
          />
        </>
      )}
      <ScheduleDetails
        frequencyCapping={{
          frequency: flight.frequencyCapNumerator
            ? `${flight.frequencyCapNumerator}`
            : undefined,
          periodLength: flight.frequencyCapDenominator
            ? `${flight.frequencyCapDenominator}`
            : undefined,
          periodUnit: flight.frequencyCapUnit || undefined,
        }}
        spendAllocation={
          !manualDays || manualDays.length === 0
            ? { mode: 'consistent' } // empty array === consistent schedule
            : { mode: 'manual', manualDays }
        }
        start={formatISODate(flight.startDate)}
        end={formatISODate(flight.endDate)}
      />
    </>
  );
};
