// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OMCIeY94H3IRmJgsCKQc {\n  --frozen-column-box-shadow: #00000008;\n\n  background-color: var(--colors-grays-white);\n  border-collapse: separate;\n  border-spacing: 0;\n  display: table;\n  height: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  min-width: var(--table-min-width);\n  position: relative;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/newTable/newTable.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;;EAErC,2CAA2C;EAC3C,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,mCAAmC;EACnC,+BAA+B;EAC/B,iCAAiC;EACjC,6BAA6B;EAC7B,iCAAiC;EACjC,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".table {\n  --frozen-column-box-shadow: #00000008;\n\n  background-color: var(--colors-grays-white);\n  border-collapse: separate;\n  border-spacing: 0;\n  display: table;\n  height: 100%;\n  margin-bottom: var(--margin-bottom);\n  margin-left: var(--margin-left);\n  margin-right: var(--margin-right);\n  margin-top: var(--margin-top);\n  min-width: var(--table-min-width);\n  position: relative;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "OMCIeY94H3IRmJgsCKQc"
};
export default ___CSS_LOADER_EXPORT___;
