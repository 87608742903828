import { prefix } from 'inline-style-prefixer';
import type { CSSProperties } from 'react';

export function prefixStyles(styles?: CSSProperties): CSSProperties {
  if (!styles) return {};
  return prefix(styles);
}

export function toCSSSize(prop?: string | number): string | undefined {
  if (prop === undefined) return;
  return typeof prop === 'number' ? `${prop}px` : `${prop}`;
}
