import styled from '@emotion/styled';
import type { HTMLAttributes, ReactNode } from 'react';

import type { MenuProduct, Product, ProductLineage } from '@jane/shared/models';
import {
  Typography,
  getResponsiveImageAttributes,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { productPhotoMetadata } from '@jane/shared/util';

export const Brand: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <Typography
    color="text-light"
    truncateAt="auto"
    variant="body"
    {...props}
    as="div"
  >
    {children}
  </Typography>
);

const getCategoryColor = (category: string) => {
  switch (category) {
    case 'indica':
      return 'purple';
    case 'sativa':
      return 'ember';
    case 'cbd':
      return 'sunset';
    case 'hybrid':
      return 'grays-black';
    default:
      return 'grays-white';
  }
};

export const Category: React.FC<
  HTMLAttributes<HTMLDivElement> & {
    category?: ProductLineage | null;
    editingMode?: boolean;
  }
> = ({ color: _, children, category, editingMode, ...props }) => {
  const color = getCategoryColor((category || '').toLowerCase());

  return (
    <Typography
      as="div"
      color={editingMode ? 'grays-white' : color}
      variant="mini-bold"
      {...props}
    >
      {children}
    </Typography>
  );
};

export const Kind: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <Typography
    as="div"
    color="text-light"
    truncateAt="auto"
    variant="body"
    {...props}
  >
    {children}
  </Typography>
);

const StyledTypography = styled(Typography)({
  '@supports (-webkit-line-clamp: 2)': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
});

export const Name: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  color: _,
  ...props
}) => (
  <StyledTypography as="div" variant="body-bold" {...props}>
    {children}
  </StyledTypography>
);

export const StrikeThroughPrice = ({ children }: { children: ReactNode }) => (
  <Typography color="text-light" strikeThrough variant="body">
    {children}
  </Typography>
);

export const useImage = (product?: Product | MenuProduct | any) => {
  const isMobile = useMobileMediaQuery({});

  const photoMetadata = product
    ? productPhotoMetadata(product)
    : { photoSource: 'jane', url: '' };

  // TODO: reefer image can generate srcset with `responsive` prop, this can be removed/refactored
  const { srcSet, imageSrc } = getResponsiveImageAttributes({
    src: photoMetadata.url,
    fallbackWidth: 200,
  });

  const imageDimension = isMobile ? '150px' : '200px';

  return {
    srcSet,
    imageSrc,
    imageDimension,
  };
};
