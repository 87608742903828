import { useMemo, useState } from 'react';

import {
  Button,
  ChevronDownIcon,
  DismissIcon,
  Flex,
  TypeAhead,
  Typography,
} from '@jane/shared/reefer';
import { us_states } from '@jane/shared/util';

export const StatesSelection = () => {
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const [query, setQuery] = useState('');

  const filteredStates = useMemo(
    () =>
      us_states
        .filter((state) =>
          state.name.toLowerCase().includes(query.toLowerCase())
        )
        .map((state) => ({ label: state.name })),
    [us_states, query]
  );

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">States to target</Typography>
      <TypeAhead
        ariaLabel="Search for states"
        listAriaLabel="Select a state from the list or search by name"
        labelHidden
        onChange={setQuery}
        onSelection={(value: string | string[]) => {
          if (Array.isArray(value)) {
            setSelectedStates(value);
          }
        }}
        selection={selectedStates}
        optionType="checkbox"
        options={filteredStates}
        popoverTarget={
          <Button
            variant="tertiary"
            label="All states"
            endIcon={<ChevronDownIcon size="sm" />}
          />
        }
        query={query}
      />

      {selectedStates && selectedStates.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {selectedStates.map((state) => (
            <Button
              label={
                <Typography color="inherit" variant="body-bold">
                  {state}
                </Typography>
              }
              key={state}
              aria-label={`Remove ${state}`}
              startIcon={<DismissIcon size="sm" />}
              variant="secondary"
              onClick={() =>
                setSelectedStates(
                  selectedStates.filter(
                    (selectedState) => selectedState !== state
                  )
                )
              }
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
