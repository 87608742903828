import { Flex, Form, useForm } from '@jane/shared/reefer';

import { BudgetSection } from './budgetSection/budgetSection';
import { DetailsSection } from './detailsSection/detailsSection';
import type { DetailsSectionProps } from './detailsSection/detailsSection';
import { ProductsSection } from './productsSection/productsSection';
import type { ProductSectionProps } from './productsSection/productsSection';
import { SpecialFormWrapper } from './specialForm.styles';
import { TargetingSection } from './targetingSection/targetingSection';

export interface SpecialFormData {
  aeropayLocationId: string;
  budgetTotal: number;
  description: string;
  discountType: string;
  dollarAmount: number;
  excludedBehavior: string;
  exclusions: string;
  includedBehavior: string;
  inclusions: string;
  managementFee: number;
  minimumAvailableBudget: number;
  percentAmount: number;
  serviceFee: number;
  targetPriceAmount: number;
  terms: string;
  title: string;
  type: string;
}

interface SpecialFormProps extends DetailsSectionProps, ProductSectionProps {}

export const SpecialForm = ({
  brands,
  categories,
  weights,
}: SpecialFormProps) => {
  const formMethods = useForm<SpecialFormData>();

  return (
    <SpecialFormWrapper>
      <Form.BaseForm
        name="brand-special-form"
        formMethods={formMethods}
        onSubmit={() => {
          return;
        }}
      >
        <Flex flexDirection="column" gap={24}>
          <DetailsSection brands={brands} />
          <BudgetSection />
          <ProductsSection weights={weights} categories={categories} />
          <TargetingSection />
        </Flex>
      </Form.BaseForm>
    </SpecialFormWrapper>
  );
};
