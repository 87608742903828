import type {
  AppMode,
  FetchMenuTopRowProps,
  JaneDM,
  JaneDMIdentifiers,
  SmartSort,
} from '@iheartjane/dm-sdk';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import type { AlgoliaProduct } from '@jane/search/types';
import type { MenuCategoryPath } from '@jane/shared/types';

import { useDmSdk } from './useDmSdk';
import { useGetJaneDMIdentifiers } from './useGetJaneDMIdentifiers';

type SharedRowProps<T> = T &
  Omit<FetchMenuTopRowProps, 'excludeCreativeIds'> &
  Partial<JaneDMIdentifiers> & {
    appMode: AppMode;
  };

type UseMenuTopRowQueryProps = SharedRowProps<{
  enabled: boolean;
  previousData: SmartSort<AlgoliaProduct> | undefined;
}>;

type UseMenuTopRowProps = SharedRowProps<{
  enableRefetch?: boolean;
  isTimerFinished: boolean;
  pageCategory?: MenuCategoryPath;
  pauseTimer: () => void;
  restartTimer: () => void;
}>;

const menuTopRowKeys = {
  all: ['sponsoredRow'] as const,
  list: (searchFilter: string | undefined, storeId: number, sdk: JaneDM) =>
    [...menuTopRowKeys.all, searchFilter, storeId, sdk] as const,
};

interface FetchMenuTopRowResponse {
  instance: SmartSort<AlgoliaProduct>;
}

const fetchMenuTopRow = async (
  context: QueryFunctionContext<ReturnType<typeof menuTopRowKeys['list']>>,
  row: SmartSort<AlgoliaProduct> | undefined
): Promise<FetchMenuTopRowResponse> => {
  const [, searchFilter, storeId, sdk] = context.queryKey;

  const instance = await sdk?.fetchMenuTopRow<AlgoliaProduct>({
    excludeCreativeIds: row?.['creativeIds'],
    searchAttributes: ['*'],
    searchFilter,
    storeId: Number(storeId),
  });

  return { instance };
};

const useMenuTopRowQuery = (props: UseMenuTopRowQueryProps) => {
  const {
    appMode,
    jdid,
    mixpanelDistinctId,
    previousData,
    storeId,
    searchFilter,
    enabled,
  } = props;
  const sdk = useDmSdk({
    appMode,
    identifier: useGetJaneDMIdentifiers({ jdid, mixpanelDistinctId }),
  });

  const { data, isError, isLoading, isSuccess, refetch } = useQuery({
    enabled,
    queryFn: async (context) => await fetchMenuTopRow(context, previousData),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: menuTopRowKeys.list(searchFilter, storeId, sdk),
  });

  return {
    isError,
    isLoading,
    isSuccess,
    refetch,
    rowInstance: data?.instance,
  };
};

interface UseMenuTopRowResponse {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  rowInstance: SmartSort<AlgoliaProduct> | undefined;
}

export const useMenuTopRow = ({
  enableRefetch = true,
  isTimerFinished,
  pageCategory,
  restartTimer,
  pauseTimer,
  ...props
}: UseMenuTopRowProps): UseMenuTopRowResponse => {
  const [menuTopRow, setMenuTopRow] = useState<
    SmartSort<AlgoliaProduct> | undefined
  >(undefined);
  const [shouldContinueRefetching, setShouldContinueRefetching] =
    useState(true);

  /**
   * Query for sponsored products
   */
  const {
    rowInstance,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchRowInstance,
  } = useMenuTopRowQuery({
    enabled: false, // Use refetch to run the query
    previousData: menuTopRow,
    ...props,
  });

  const refetchInstance = useCallback(async () => {
    const { data } = await refetchRowInstance();

    if (data?.instance && data.instance.products.length > 0) {
      setMenuTopRow(data.instance);
    } else {
      setShouldContinueRefetching(false);
      pauseTimer();
    }
  }, [refetchRowInstance]);

  /**
   * Restart timer and refetch on page change/load
   *  */
  useEffect(() => {
    restartTimer();
    refetchInstance();
  }, [pageCategory]);

  /**
   * Restart the refresh timer when time has elapsed
   */
  useEffect(() => {
    if (isTimerFinished) {
      if (enableRefetch && shouldContinueRefetching) {
        refetchInstance();
      }
      restartTimer();
    }
  }, [isTimerFinished, enableRefetch]);

  /**
   * Check to make sure we should render the row
   */
  const isRowValid = menuTopRow?.products?.length ?? -1 > 0;

  return {
    isError,
    isLoading,
    isSuccess,
    rowInstance: isRowValid ? menuTopRow : rowInstance,
  };
};
