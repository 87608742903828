import { useEffect, useState } from 'react';

import {
  Button,
  CheckboxField,
  Flex,
  Form,
  TrashIcon,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import type { SpecialFormData } from '../specialForm';

const CustomerBehaviorSelection = ({
  resetSegment,
  type,
  watchValue,
}: {
  resetSegment: () => void;
  type: 'included' | 'excluded';
  watchValue: string;
}) => {
  const fieldName = `${type}Behavior`;

  return (
    <Flex flexDirection="row" gap={8}>
      <Form.SelectField
        name={fieldName}
        label="Customer behavior"
        options={[
          { label: 'New users', value: 'newUsers' },
          { label: 'Return users', value: 'returnUsers' },
          { label: 'Lapsed users', value: 'lapsedUsers' },
        ]}
        placeholder="Select"
        borderRadius="xs"
        width="373px"
        disableMobileInputStyling
        validate={(v) =>
          !!v && v === watchValue ? 'Same behaviors are not permitted.' : true
        }
        data-testid={`${type}-behavior`}
      />
      <Button.Icon
        icon={<TrashIcon />}
        onClick={resetSegment}
        style={{ marginTop: 38 }}
        ariaLabel={`Reset ${type} customer behavior`}
      />
    </Flex>
  );
};

export const SegmentsSelection = () => {
  const [showExcludeSegments, setShowExcludeSegments] = useState(false);
  const { resetField, setValue, trigger, watch } =
    useFormContext<SpecialFormData>();

  const [includedSegment, excludedSegment] = watch([
    'includedBehavior',
    'excludedBehavior',
  ]);

  const resetSegment = (name: 'includedBehavior' | 'excludedBehavior') => {
    resetField(name);
    setValue(name, '');
  };

  useEffect(() => {
    trigger(['includedBehavior', 'excludedBehavior']);
  }, [includedSegment, excludedSegment]);

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">Customer segments to target</Typography>
      <CustomerBehaviorSelection
        type="included"
        watchValue={excludedSegment}
        resetSegment={() => {
          resetSegment('includedBehavior');
        }}
      />
      <CheckboxField
        name="excludeCustomers"
        label="Exclude customers"
        onChange={(c) => {
          if (showExcludeSegments) {
            resetField('excludedBehavior');
            trigger('includedBehavior');
          }
          setShowExcludeSegments(c);
        }}
        my={12}
      />
      {showExcludeSegments && (
        <CustomerBehaviorSelection
          type="excluded"
          watchValue={includedSegment}
          resetSegment={() => {
            resetSegment('excludedBehavior');
          }}
        />
      )}
    </Flex>
  );
};
