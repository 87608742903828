import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import { marginCSSVars } from '@jane/shared/reefer';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import '../../../../reefer/src/styles/theme.css';
import type { TableProps } from '../table.types';
import { TableSettingsContext } from '../useTableSettings';
import styles from './newTable.module.css';

export type NewTableProps = Omit<
  TableProps,
  'scrollable' | 'maxHeight' | 'maxWidth'
>;

export function NewTable({
  children,
  className,
  'data-testid': testId,
  freezeLastColumn = false,
  freezeFirstColumn = false,
  minWidth = '100%',
  style,
  ...marginProps
}: NewTableProps) {
  const tableSettings = useMemo(
    () => ({
      freezeFirstColumn,
      freezeLastColumn,
    }),
    [freezeFirstColumn, freezeLastColumn]
  );
  return (
    <TableSettingsContext.Provider value={tableSettings}>
      <table
        className={clsx(className, styles.table, {})}
        data-testid={testId}
        style={
          {
            ...marginCSSVars(marginProps),
            '--table-min-width': minWidth,
            ...style,
          } as CSSProperties
        }
      >
        {children}
      </table>
    </TableSettingsContext.Provider>
  );
}
