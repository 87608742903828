import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { parseSearch } from '@jane/shared/util';

const filterParamsToFilters = (parsedParams: Record<string, any>) => {
  const filters = Object.entries(parsedParams).map(([key, value]) => {
    return {
      [key]: value.split(','),
    };
  });

  return filters.length ? Object.assign({}, ...filters) : {};
};

export const useSpecialsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = searchParams.toString();

  const onFilterChange = useCallback(
    (filterKey: string, filterValue: (string | number)[] | string) => {
      setSearchParams((searchParams) => {
        const newFilters = new URLSearchParams(searchParams);
        newFilters.delete(filterKey);

        if (Array.isArray(filterValue) && filterValue.length) {
          newFilters.append(filterKey, filterValue.join(','));
        } else if (!Array.isArray(filterValue) && filterValue.length) {
          newFilters.set(filterKey, filterValue);
        }
        return newFilters;
      });
    },
    [setSearchParams]
  );

  const specialsFilters = useMemo(() => {
    const parsedFilters = parseSearch(filterParams);
    return filterParamsToFilters(parsedFilters);
  }, [filterParams]);

  return {
    onFilterChange,
    filters: specialsFilters,
  };
};
