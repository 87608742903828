import { ActiveFilterTag, StyledFilterList } from '@jane/shared-b2b/components';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Box,
  Button,
  ChevronDownIcon,
  Flex,
  NumberField,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { DeselectFiltersButton } from './deselectFiltersButton';
import { FilterButtonGrid } from './specialsFilter.styles';
import {
  BUDGET_RANGE_MAP,
  BUDGET_RANGE_OPTIONS,
} from './specialsFiltersOptions';

export interface BudgetRangeFilterProps {
  currentValue: number[];
  handleChange: (updatedValue: number[]) => void;
}

export const BudgetRangeFilter = ({
  currentValue,
  handleChange,
}: BudgetRangeFilterProps) => {
  const label = 'Budget';
  const [min, max] = currentValue;

  // TODO
  const isSelected = false;
  // currentValueSet.has(value)

  const handleNumberFieldChange = (value: number, index: 0 | 1) => {
    const updatedValue = [min, max];
    updatedValue[index] = value;
    handleChange(updatedValue);
  };

  const handleRangeButtonChange = (value: string) =>
    handleChange(BUDGET_RANGE_MAP[value]);

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={currentValue.length === 0 ? 'tertiary' : 'tertiary-selected'}
          endIcon={
            <Flex alignItems="center">
              <ActiveFilterTag
                valuesCount={currentValue.length ? 1 : 0}
                label={label}
              />
              <ChevronDownIcon color="inherit" size="sm" />
            </Flex>
          }
        />
      }
      label={label}
      disableMobileStyling
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content padding={false}>
          <StyledFilterList aria-label={`${label} inputs`}>
            <Flex as="li" alignItems="center" px={24} pt={12}>
              <NumberField
                label="Min budget"
                name="Min budget"
                onChange={(value) =>
                  value &&
                  typeof value === 'number' &&
                  handleNumberFieldChange(value, 0)
                }
                placeholder="Min price"
                labelHidden
                width="118px"
                disableMobileInputStyling
              />
              <Typography as="span" mx={2} mt={12}>
                &#8211;
              </Typography>
              <NumberField
                label="Max budget"
                name="Max budget"
                onChange={(value) =>
                  value &&
                  typeof value === 'number' &&
                  handleNumberFieldChange(value, 1)
                }
                placeholder="Max price"
                labelHidden
                width="118px"
                disableMobileInputStyling
              />
            </Flex>
            <Box as="hr" height="1px" background="grays-light" m={24} />
            <FilterButtonGrid>
              {BUDGET_RANGE_OPTIONS.map(({ label, value }) => (
                <Box as="li" width="100%" key={label}>
                  <Button
                    label={label}
                    variant={isSelected ? 'tertiary-selected' : 'tertiary'}
                    onClick={() => handleRangeButtonChange(value)}
                    full
                  />
                </Box>
              ))}
            </FilterButtonGrid>
            <DeselectFiltersButton
              onClick={() => {
                handleChange([]);
                closePopover();
              }}
              label="Clear budgets"
            />
          </StyledFilterList>
        </Popover.Content>
      )}
    </Popover>
  );
};
