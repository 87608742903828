import { useInfiniteQuery } from '@tanstack/react-query';
import qs from 'qs';

import { brandsApi } from '@jane/brands/data-access';
import { createUseQueryHook } from '@jane/shared/data-access';
import { parseData } from '@jane/shared/util';

import type {
  BrandSpecial,
  FetchBrandSpecialParams,
  FetchBrandSpecialsParams,
} from './brandSpecials.types';
import {
  BrandSpecialSchema,
  BrandSpecialsResponseSchema,
} from './brandSpecials.types';
import { queryKeys } from './brandSpecials.utils';

export const BRAND_SPECIALS_URL = '/jane_gold/brand_specials';

const fetchBrandSpecial = async ({ id }: FetchBrandSpecialParams) => {
  const { data } = await brandsApi.get(BRAND_SPECIALS_URL + `/${id}`);
  const parsedData = parseData(BrandSpecialSchema, data);
  return parsedData;
};

export const useFetchBrandSpecial = createUseQueryHook<
  FetchBrandSpecialParams,
  BrandSpecial
>(fetchBrandSpecial, queryKeys.brandSpecial);

const fetchBrandSpecials = async ({
  discountType,
  endTime,
  limit,
  offset,
  productBrandIds,
  remainingBudgetEnd,
  remainingBudgetStart,
  specialStatus,
  startTime,
}: FetchBrandSpecialsParams) => {
  const queryParams = {
    limit,
    offset,
    date_range: [startTime, endTime],
    special_status: specialStatus,
    product_brand_ids: productBrandIds,
    discount_type: discountType,
    remaining_budget_range: [remainingBudgetStart, remainingBudgetEnd],
  };
  const queryString = `?${qs.stringify(queryParams, {
    arrayFormat: 'brackets',
  })}`;

  const { data } = await brandsApi.get(BRAND_SPECIALS_URL + queryString);
  const parsedData = parseData(BrandSpecialsResponseSchema, data);
  return parsedData.brand_specials;
};

export const useFetchBrandSpecials = createUseQueryHook<
  FetchBrandSpecialsParams,
  BrandSpecial[]
>(fetchBrandSpecials, queryKeys.brandSpecials);

export const useInfiniteBrandSpecials = ({
  enabled,
  ...params
}: FetchBrandSpecialsParams & { enabled: boolean; perPage: number }) => {
  return useInfiniteQuery({
    enabled,
    queryFn: async ({ pageParam = 0 }) => {
      const brandSpecials = await fetchBrandSpecials({
        ...params,
        offset: pageParam * params.perPage,
        limit: params.perPage,
      });

      return { brandSpecials, pageParam };
    },
    queryKey: queryKeys.infiniteBrandSpecials(params),
    getNextPageParam: (lastPage) => {
      const isLastPage = (lastPage.brandSpecials || []).length < params.perPage;
      return isLastPage ? undefined : lastPage.pageParam + 1;
    },
  });
};
