import styled from '@emotion/styled';

import { ButtonToggle } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

export const StyledTableRow = styled(Table.Row)(({ theme }) => ({
  '&:hover > td': {
    backgroundColor: theme.colors.grays.hover,
  },
}));

export const StyledButtonToggle = styled(ButtonToggle)(({ theme }) => ({
  backgroundColor: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.sm,
  '> button': {
    borderRadius: theme.borderRadius.sm,
  },
}));
