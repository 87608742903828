import type { MutateOptions } from '@tanstack/react-query';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import range from 'lodash/range';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type {
  AdSubmission,
  AdType,
  ApiFlightDetails,
  ApiFlightSummary,
  JamApiAd,
} from '@jane/ad-manager/data-access';
import {
  AdTypeEnum,
  JamApiAdSchema,
  convertIdsToNumber,
  scheduleForWeek,
  toFrequencyUnitCode,
  transformDayParts,
  useSubmitAd,
  zoneToPlatforms,
} from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import type {
  BidModifiers,
  CPCAdPlacement,
  CPMAdPlacement,
  DisplayPlatformsOptionIdentifier,
  ManualScheduleForDays,
  ProductBrand,
} from '@jane/ad-manager/types';
import type { JamError } from '@jane/ad-manager/util';
import {
  dayAbbreviationFromIndex,
  dayNameFromIndex,
  formatPacificTimeToUtc,
  formatPacificTimeToUtcEod,
  handleJamError,
  parsePacificTime,
  placementsToZones,
} from '@jane/ad-manager/util';
import { parseData } from '@jane/shared/util';

import {
  duplicateBidModifiers,
  transformBidModifiersToJam,
} from './Pricing/BidModifiers';
import type { SelectionModeOptions } from './Products/AdProductsSection';
import {
  PRODUCT_CATEGORIES,
  type ProductCategory,
} from './Products/categories';
import type { DailySchedule } from './Scheduling/DailySchedule';

export const defaultCPCPlacements = [
  'Recommended row',
  'Cart toppers',
  'Inline product',
  'Product page',
] as CPCAdPlacement[];

export type AdPlacement = (CPMAdPlacement | CPCAdPlacement)[];
type FrequencyCapUnit = ApiFlightSummary['frequencyCapUnit'];

export enum SpendAllocationOption {
  Consistent = 'consistent_spend_allocation',
  Manual = 'manual_spend_allocation',
  Weekly = 'weekly_spend_allocation',
}

export type FlatAdSubmissionForm = {
  _hasStoreExclusion?: boolean;
  _isDuplicate?: boolean;
  _manualScheduleForDays: ManualScheduleForDays;
  _productTargetingOption: SelectionModeOptions;
  _spendAllocationOption?: SpendAllocationOption;
  adPlacement: AdPlacement;
  adType: AdType;
  bid: number | null;
  bidModifiers: BidModifiers;
  budget: number;
  creditPercent: number;
  customerSegments: string;
  dayParting: DailySchedule;
  displayPlatforms: DisplayPlatformsOptionIdentifier;
  endDate: string;
  excludedStoreIds: string[];
  impressions: number;
  isPriority: boolean;
  menuRowTitle: string | null;
  productCategories: ProductCategory[];
  productIds: string[];
  startDate: string;
  states: string[];
  storeIds: string[];
  timePeriod: number;
  timeUnit: FrequencyCapUnit;
};

export const DEFAULT_PRIORITY_FLAT_IMPRESSIONS = 1;
export const DEFAULT_PRIORITY_FLAT_TIME_PERIOD = 1;
export const DEFAULT_PROGRAMMATIC_CPM_IMPRESSIONS = 2;
export const DEFAULT_PROGRAMMATIC_CPM_TIME_PERIOD = 30;
export const DEFAULT_PROGRAMMATIC_CPC_IMPRESSIONS = 5;
export const DEFAULT_PROGRAMMATIC_CPC_TIME_PERIOD = 10;

export const DISPLAY_PLATFORMS_IDENTIFIER = {
  all: 'all-platforms',
  web: 'web',
  kiosk: 'kiosk',
} as const;

export const defaultAdSubmissionFormData: FlatAdSubmissionForm = {
  _isDuplicate: false,
  _manualScheduleForDays: {},
  _productTargetingOption: 'automatic',
  _hasStoreExclusion: false,
  adPlacement: ['Menu row', 'Product page'],
  adType: AdTypeEnum.enum.cpm,
  bid: 0,
  bidModifiers: {},
  budget: 0,
  creditPercent: 0,
  customerSegments: '',
  dayParting: {},
  displayPlatforms: DISPLAY_PLATFORMS_IDENTIFIER.all,
  endDate: '',
  excludedStoreIds: [],
  impressions: DEFAULT_PROGRAMMATIC_CPM_IMPRESSIONS,
  isPriority: false,
  menuRowTitle: '',
  productCategories: [],
  productIds: [],
  startDate: '',
  states: [],
  storeIds: [],
  timePeriod: DEFAULT_PROGRAMMATIC_CPM_TIME_PERIOD,
  timeUnit: 'minutes',
};

const formToJamApiPostBody = (formData: FlatAdSubmissionForm): JamApiAd => {
  const isPriority = formData.isPriority;
  const adType = formData.adType;

  return {
    ad_model: isPriority ? AdTypeEnum.enum.flat : adType,
    ad_type: isPriority ? 'priority' : 'programmatic',
    bid: isPriority ? null : formData.bid,
    bidModifiers: formData.bidModifiers
      ? transformBidModifiersToJam(formData.bidModifiers)
      : [],
    budget: formData.budget,
    categories: formData.productCategories.map(({ value }) => value) || [],
    credit_percent: formData.creditPercent || 0,
    days: formData.dayParting ? transformDayParts(formData.dayParting) : [],
    end_date: formatPacificTimeToUtcEod(formData.endDate),
    excluded_stores: convertIdsToNumber(formData.excludedStoreIds || []),
    impressions: formData.impressions,
    platforms: zoneToPlatforms(formData.displayPlatforms),
    products: convertIdsToNumber(formData.productIds || []),
    segment_id:
      formData.customerSegments && formData.customerSegments.length > 0
        ? Number(formData.customerSegments[0])
        : null,
    start_date: formatPacificTimeToUtc(formData.startDate),
    states: isPriority ? [] : formData.states,
    stores: convertIdsToNumber(formData.storeIds || []),
    time_period: formData.timePeriod,
    time_unit: toFrequencyUnitCode(formData.timeUnit),
    title: adType === AdTypeEnum.enum.cpm ? formData.menuRowTitle : null,
    zones: placementsToZones(formData.adPlacement),
  };
};

export type UseAdBuilderFormReturn = {
  formMethods: UseFormReturn<FlatAdSubmissionForm>;
  onSubmit: () => Promise<void>;
  resetWithDefaults: () => void;
};

interface UseAdBuilderForm {
  ({
    onSuccess,
    onError,
  }: {
    onError?: (submitError: Error) => void;
    onSuccess?: MutateOptions<AdSubmission, Error, JamApiAd>['onSuccess'];
  }): UseAdBuilderFormReturn;
}

export const useAdBuilderForm: UseAdBuilderForm = ({ onSuccess, onError }) => {
  const { selectedBrand } = useSelectedBrandContext();

  const formMethods = useForm<FlatAdSubmissionForm>({
    defaultValues: defaultAdSubmissionFormData,
    mode: 'onSubmit',
  });

  const { mutate: submitAd } = useSubmitAd(selectedBrand?.id);
  const { reset, handleSubmit } = formMethods;
  const resetWithDefaults = () => reset(defaultAdSubmissionFormData);
  const onSubmit = (formData: FlatAdSubmissionForm) => {
    const adSubmission = formToJamApiPostBody(formData);

    let validatedSubmission: JamApiAd;

    // react-use-form swallows this zod error otherwise
    try {
      validatedSubmission = parseData(JamApiAdSchema, adSubmission);
    } catch (e) {
      handleJamError(e as JamError).then();
      throw e;
    }

    return new Promise((resolve, reject) => {
      submitAd(validatedSubmission, {
        onSuccess: (data, variables, context) => {
          resetWithDefaults();
          if (onSuccess) onSuccess(data, variables, context);
          resolve(data);
        },
        onError: (submitError: Error) => {
          handleJamError(submitError).then();
          if (onError) onError(submitError);
          reject(submitError);
        },
      });
    });
  };
  const onHandleSubmit = handleSubmit(onSubmit);
  return { formMethods, onSubmit: onHandleSubmit, resetWithDefaults };
};

export const buildDuplicateFlightDetailsFormData = (
  flight: ApiFlightDetails,
  selectedBrand?: ProductBrand
): FlatAdSubmissionForm => {
  // we don't have a way of displaying "Mixed" in the UI currently so we filter it out
  const adPlacement = flight.zones.filter((z) => z !== 'Mixed') as AdPlacement;

  const isPriority = flight.model === 'Flat';
  const adType = isPriority
    ? AdTypeEnum.enum.cpm
    : (flight.model.toLocaleLowerCase() as FlatAdSubmissionForm['adType']);
  const budget = isPriority ? flight.bid : flight.dailyBudget;
  const weekSchedule = scheduleForWeek(flight.schedule);
  const dayParting = range(0, 7).reduce((acc, dayIndex) => {
    const scheduleForDay: ApiFlightDetails['schedule'][0] | undefined =
      weekSchedule[dayAbbreviationFromIndex(dayIndex)];

    if (scheduleForDay === undefined) return acc;

    return {
      ...acc,
      [dayNameFromIndex(dayIndex)]: {
        startTime: parsePacificTime(scheduleForDay.startTime).format('hh:mm A'),
        endTime: parsePacificTime(scheduleForDay.endTime).format('hh:mm A'),
      },
    };
  }, {});

  const schedulesWithoutAllDaySetting = flight.schedule
    // remove all-day entries
    .filter(
      (entry) =>
        !(entry.startTime === '00:00:00' && entry.endTime === '23:59:00')
    );

  const manuallyScheduledDays =
    schedulesWithoutAllDaySetting.reduce<ManualScheduleForDays>(
      (acc, { weekDays }) => {
        weekDays.forEach((value) => {
          acc[value] = true;
        });
        return acc;
      },
      {}
    );

  const getDuplicateProductTargetingOption = (): SelectionModeOptions => {
    if (productCategoriesFromKeywords(flight.keywords).length > 0) {
      return 'category';
    } else if (flight.selectedProducts.length > 0) {
      return 'manual';
    }
    return 'automatic';
  };

  return buildDuplicatedFormDataWithDefaults({
    _spendAllocationOption:
      flight.schedule.length === 0
        ? SpendAllocationOption.Consistent
        : SpendAllocationOption.Manual,
    _manualScheduleForDays: manuallyScheduledDays,
    _productTargetingOption: getDuplicateProductTargetingOption(),
    _hasStoreExclusion: flight.excludedStores.length > 0,
    adPlacement,
    adType,
    bid: flight.bid,
    bidModifiers: duplicateBidModifiers(flight.bidModifiers),
    budget,
    creditPercent: flight.creditPercent || 0,
    displayPlatforms: platformsFromDisplaysOnProp(flight.displaysOn),
    dayParting,
    impressions: flight.frequencyCapNumerator || undefined,
    isPriority,
    menuRowTitle: flight.title || selectedBrand?.name,
    productIds: flight.selectedProducts.map((pdt) => String(pdt.id)),
    productCategories: productCategoriesFromKeywords(flight.keywords),
    states: flight.states || undefined,
    excludedStoreIds: flight.excludedStores.map((store) => store.id.toString()),
    timePeriod: flight.frequencyCapDenominator || undefined,
    timeUnit: flight.frequencyCapUnit as FlatAdSubmissionForm['timeUnit'],
    storeIds: flight.stores.map((store) => store.id.toString()),
  });
};

export const buildDuplicatedFormDataWithDefaults: (
  formData: Partial<FlatAdSubmissionForm>
) => FlatAdSubmissionForm = (formData) => {
  const sparseFormData = omitBy(
    formData,
    isUndefined
  ) as Partial<FlatAdSubmissionForm>;
  sparseFormData['endDate'] = '';
  sparseFormData['startDate'] = '';

  return {
    ...defaultAdSubmissionFormData,
    ...sparseFormData,
    _isDuplicate: true,
  };
};

export const productCategoriesFromKeywords = (
  keywords: ApiFlightDetails['keywords']
) =>
  keywords.reduce((acc, keyword) => {
    const matchingCategory = PRODUCT_CATEGORIES.find(
      (category) => category.value === keyword.name
    );

    if (!matchingCategory) {
      return acc;
    }

    return [...acc, matchingCategory];
  }, [] as typeof PRODUCT_CATEGORIES);

export const platformsFromDisplaysOnProp = (
  displaysOn: string | null
): DisplayPlatformsOptionIdentifier => {
  switch (displaysOn) {
    case 'Web':
      return DISPLAY_PLATFORMS_IDENTIFIER.web;
    case 'Kiosk':
      return DISPLAY_PLATFORMS_IDENTIFIER.kiosk;
    default:
    case null:
    case '-':
    case 'All':
      return DISPLAY_PLATFORMS_IDENTIFIER.all;
  }
};
