import { Outlet } from 'react-router-dom';

import {
  useAuth,
  useIdentifyDatadogUser,
  useIdentifyLDContext,
  useIdentifyMixpanelUser,
  useIdentifyTrackJsUser,
} from '@jane/brands/hooks';

import { Loading } from './util/Loading';

/**
 * This component handles identifying the logged in user with external services
 * used for functionality and observability. This should be the top level route
 * in the router and wrap the entire application.
 */
export const ExternalIdentityManager = () => {
  const { userQuery } = useAuth();
  const user = userQuery.data;

  const { isIdentifying } = useIdentifyLDContext(user);

  useIdentifyMixpanelUser(user);
  useIdentifyTrackJsUser(user?.email);
  useIdentifyDatadogUser(user);

  if (isIdentifying) {
    return <Loading fullPage />;
  }

  return <Outlet />;
};
