import styled from '@emotion/styled';
import pluralize from 'pluralize-esm';
import { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import type { DisplayPlatformsOptionIdentifier } from '@jane/ad-manager/types';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../AdBuilderSectionContainer';
import type { AdPlacement, FlatAdSubmissionForm } from '../useAdBuilderForm';
import { CustomerSegmentsTargetForm } from './CustomerSegments/CustomerSegmentsTargetForm';
import { StateTargetsForm } from './States/StateTargetsForm';
import { StoreTargetsForm } from './Stores/StoreTargetsForm';

export const AdTargetingSectionContainer = styled.div(() => ({
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr auto',
}));

export const MAX_TARGETED_SITE_ZONES = 400;

const displayPlatformIdToZonesCount: Record<
  DisplayPlatformsOptionIdentifier,
  number
> = {
  'all-platforms': 3, // embed, marketplace, kiosk
  web: 2, // embed, marketplace
  kiosk: 1,
};

const getStoreAvailableCount = (
  adPlacement: AdPlacement,
  displayPlatformId: DisplayPlatformsOptionIdentifier,
  includedStoreIds: string[],
  excludedStoreIds: string[]
) => {
  const adPlacementCount = adPlacement.length;
  const displayPlatformsCount =
    displayPlatformIdToZonesCount[displayPlatformId];
  const totalPlacementsAndDisplays = adPlacementCount * displayPlatformsCount;
  const currentSiteZones =
    (includedStoreIds.length + excludedStoreIds.length) *
    totalPlacementsAndDisplays;

  return Math.floor(
    (MAX_TARGETED_SITE_ZONES - currentSiteZones) / totalPlacementsAndDisplays
  );
};

export const AdTargetingSection = () => {
  const {
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: {
      errors,
      touchedFields: {
        isPriority: isPriorityTouched,
        states: isStatesTouched,
        storeIds: isStoreIdsTouched,
      },
    },
  } = useFormContext<FlatAdSubmissionForm>();

  const [adPlacement, displayPlatforms, isPriority, states] = watch([
    'adPlacement',
    'displayPlatforms',
    'isPriority',
    'states',
  ]);

  const {
    field: {
      onChange: onStoreIdsChange,
      onBlur: onBlurStoreIdsChange,
      value: includedStoreIds,
    },
  } = useController<FlatAdSubmissionForm, 'storeIds'>({
    name: 'storeIds',
  });

  const handleOnStoreIdsChange = (val: FlatAdSubmissionForm['storeIds']) => {
    // since there is no underlying select input element here we need to
    // call blur manually to trigger isTouched and the adSection validation
    onBlurStoreIdsChange();
    onStoreIdsChange(val);
  };

  const {
    field: { onChange: onExcludedStoreIdsChange, value: excludedStoreIds },
  } = useController<FlatAdSubmissionForm, 'excludedStoreIds'>({
    name: 'excludedStoreIds',
  });

  const hasStoreExclusion = useWatch<
    FlatAdSubmissionForm,
    '_hasStoreExclusion'
  >({
    name: '_hasStoreExclusion',
    defaultValue: getValues('_hasStoreExclusion'),
  });

  const [isStoresFormOpen, setStoresFormOpen] = useState(
    includedStoreIds.length > 0
  );
  const [isCustomerSegmentsFormOpen, setCustomerSegmentsFormOpen] =
    useState(false);

  const storeTargetAvailableCount = useMemo(
    () =>
      getStoreAvailableCount(
        adPlacement,
        displayPlatforms,
        includedStoreIds,
        excludedStoreIds
      ),
    [
      adPlacement.length,
      displayPlatforms.length,
      includedStoreIds.length,
      excludedStoreIds.length,
    ]
  );

  useEffect(() => {
    if (!isPriorityTouched && !isStatesTouched && !isStoreIdsTouched) {
      return;
    }

    clearErrors('root.adTargeting');
    clearErrors('root.priorityTargeting');

    if (storeTargetAvailableCount < 0) {
      const storesToRemove = storeTargetAvailableCount * -1;
      setError('storeIds', {
        type: 'storeIds',
        message: `Target limit exceeded. Please remove ${storesToRemove} store ${pluralize(
          'target',
          storesToRemove
        )} and/or reduce ad placements or display platforms.`,
      });
      setError('excludedStoreIds', {
        type: 'excludedStoreIds',
        message: `Target limit exceeded. Please remove ${storesToRemove} store ${pluralize(
          'target',
          storesToRemove
        )} and/or reduce ad placements or display platforms.`,
      });
    } else {
      clearErrors('storeIds');
      clearErrors('excludedStoreIds');
    }
  }, [
    isPriority,
    includedStoreIds.length,
    states.length,
    setError,
    clearErrors,
    isPriorityTouched,
    isStatesTouched,
    isStoreIdsTouched,
    storeTargetAvailableCount,
  ]);

  const toggleStoreExclusion = () => {
    const currentExclusionState = getValues('_hasStoreExclusion');
    setValue('_hasStoreExclusion', !currentExclusionState);

    // If turning off exclusion, clear the excluded store IDs
    if (currentExclusionState) {
      setValue('excludedStoreIds', []);
    }
  };

  return (
    <AdBuilderSectionContainer data-testid="ad-targeting-section">
      <Typography variant="header-bold" branded>
        Target states, stores, and customers
      </Typography>
      {errors.root?.['adTargeting'] && (
        <Typography color="error" role="alert">
          {errors.root['adTargeting'].message}
        </Typography>
      )}
      {errors.root?.['priorityTargeting'] && (
        <Typography color="error" role="alert">
          {errors.root['priorityTargeting'].message}
        </Typography>
      )}
      {!isPriority && (
        <>
          <Flex data-testid="states-targeting-section">
            <StateTargetsForm />
          </Flex>
          <Modal.ContentDivider color="grays-light" />
        </>
      )}

      <Flex>
        {isStoresFormOpen ||
        includedStoreIds.length > 0 ||
        (states.length > 0 && excludedStoreIds.length > 0) ? (
          <StoreTargetsForm
            availableTargets={storeTargetAvailableCount}
            isExcludedStores={false}
            onStoreIdsChange={handleOnStoreIdsChange}
            storeIds={includedStoreIds}
          />
        ) : (
          <EmptyStoresForm onClickOpen={() => setStoresFormOpen(true)} />
        )}
      </Flex>

      {(isStoresFormOpen ||
        includedStoreIds.length > 0 ||
        (states.length > 0 && excludedStoreIds.length > 0)) && (
        <Flex mt={20}>
          <CheckboxField
            label="Exclude stores"
            name="excludeStores"
            checked={hasStoreExclusion}
            onChange={toggleStoreExclusion}
          />
        </Flex>
      )}

      {hasStoreExclusion && (
        <Flex mt={32}>
          <StoreTargetsForm
            availableTargets={storeTargetAvailableCount}
            isExcludedStores={true}
            onStoreIdsChange={onExcludedStoreIdsChange}
            storeIds={excludedStoreIds}
          />
        </Flex>
      )}

      <Modal.ContentDivider color="grays-light" />

      <Flex>
        {isCustomerSegmentsFormOpen ? (
          <CustomerSegmentsTargetForm
            setCustomerSegmentsFormOpen={setCustomerSegmentsFormOpen}
          />
        ) : (
          <EmptyCustomerSegmentForm
            onClickOpen={() => setCustomerSegmentsFormOpen(true)}
          />
        )}
      </Flex>
    </AdBuilderSectionContainer>
  );
};

interface ClickOpenProps {
  onClickOpen: () => void;
}

const EmptyStoresForm = ({ onClickOpen }: ClickOpenProps) => (
  <AdTargetingSectionContainer>
    <Flex flexDirection="column" grow={1}>
      <Typography variant="body-bold">Stores to target</Typography>
      <Typography variant="body" pr={8}>
        All stores within states defined above will be targeted by default. You
        can override this by adding or excluding individual stores.
      </Typography>
    </Flex>
    <Button onClick={onClickOpen} variant="tertiary" label="Customize stores" />
  </AdTargetingSectionContainer>
);

const EmptyCustomerSegmentForm = ({ onClickOpen }: ClickOpenProps) => (
  <AdTargetingSectionContainer>
    <Flex flexDirection="column" grow={1}>
      <Typography variant="body-bold">Customer segment to target</Typography>
      <Typography variant="body" pr={8}>
        Target customers by their purchasing behaviors over a set time period.
      </Typography>
    </Flex>
    <Button
      variant="tertiary"
      label="Add customer segment"
      onClick={onClickOpen}
    />
  </AdTargetingSectionContainer>
);
