import { z } from 'zod';

export const zPublicImage = z.object({
  id: z.number(),
  position: z.number().nullable(),
  urls: z.object({
    small: z.string().nullable(),
    medium: z.string().nullable(),
    extraLarge: z.string().nullable(),
    original: z.string().nullable(),
  }),
});
