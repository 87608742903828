import type { SelectFieldProps } from '../fields/field.types';
import { SelectField } from '../fields/selectField/selectField';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormSelectFieldProps
  extends Omit<SelectFieldProps, 'children'>,
    Pick<FormFieldProps, 'required' | 'shouldUnregister' | 'validate'> {}

/**
 * Select field allow users to choose one option from a dropdown of options.
 *
 * Use this component *inside forms*
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`SelectField`](/story/components-forms-fields-selectfield--default).*
 */

export function FormSelectField({
  autoFocus = false,
  defaultValue: defaultValueProp,
  disabled = false,
  helperText,
  label,
  labelHidden = false,
  name,
  onBlur,
  onChange,
  options,
  placeholder,
  required = false,
  'data-testid': testId,
  shouldUnregister,
  validate,
  width = 'auto',
  ...props
}: FormSelectFieldProps) {
  const defaultValue =
    required && !placeholder && !defaultValueProp
      ? options[0].value.toString()
      : defaultValueProp;

  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      name={name}
      render={({ children, field }) => (
        <SelectField
          autoFocus={autoFocus}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          helperText={helperText}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(fieldValue) => {
            onBlur && onBlur(fieldValue);
            field.onBlur();
          }}
          onChange={(fieldValue) => {
            onChange && onChange(fieldValue);
            field.onChange(fieldValue);
          }}
          onUpdate={onChange}
          options={options}
          placeholder={placeholder}
          ref={field.ref}
          data-testid={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </SelectField>
      )}
      required={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
