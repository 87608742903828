import { useState } from 'react';

import { Button, Card, Flex, Typography } from '@jane/shared/reefer';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { SegmentsSelection } from './segmentsSelection';
import { StatesSelection } from './statesSelection';
import { StoresSelection } from './storesSelection';

export const TargetingSection = () => {
  const [isStatesFormOpen, setStatesFormOpen] = useState(false);
  const [isStoresFormOpen, setStoresFormOpen] = useState(false);
  const [isSegmentsFormOpen, setSegmentsFormOpen] = useState(false);

  return (
    <Card width="100%" style={{ overflow: 'visible' }}>
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography branded variant="header-bold" mb={32}>
            Target states, stores and customers
          </Typography>
          {!isStatesFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">States to target</Typography>
                <Typography>All states will be targeted by default.</Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Customize states"
                ml="auto"
                onClick={() => setStatesFormOpen(true)}
              />
            </Flex>
          ) : (
            <StatesSelection />
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          {!isStoresFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">Stores to target</Typography>
                <Typography>
                  All stores within states defined above will be targeted by
                  default. You can override this by adding or excluding
                  individual stores.
                </Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Customize stores"
                ml="auto"
                onClick={() => setStoresFormOpen(true)}
              />
            </Flex>
          ) : (
            <StoresSelection />
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          {!isSegmentsFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">
                  Customer segments to target
                </Typography>
                <Typography>
                  Target customers by their purchasing behaviors over a set time
                  period.
                </Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Add customer segments"
                ml="auto"
                onClick={() => setSegmentsFormOpen(true)}
              />
            </Flex>
          ) : (
            <SegmentsSelection />
          )}
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
