import { useState } from 'react';

import { Button, DismissIcon, Flex, Typography } from '@jane/shared/reefer';

import type { MultiSelectOption } from '../../multiSelectFilter';
import { MultiSelectFilter } from '../../multiSelectFilter';

export const WeightSelection = ({ weights }: { weights: string[] }) => {
  const [selectedWeights, setSelectedWeights] = useState<(string | number)[]>(
    []
  );

  const weightOptions: MultiSelectOption[] = weights.map((weight) => {
    return { label: weight, value: weight };
  });

  return (
    <Flex flexDirection="column" gap={16}>
      <Flex flexDirection="row" width="100%" alignItems="center" mt={16}>
        <Typography>Weight</Typography>
        <MultiSelectFilter
          currentValue={selectedWeights}
          handleChange={setSelectedWeights}
          label={selectedWeights.length > 0 ? 'Weight' : 'All weights'}
          options={weightOptions}
          alignment={{ horizontal: 'right', vertical: 'bottom' }}
          ml="auto"
        />
      </Flex>
      {selectedWeights && selectedWeights.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {selectedWeights.map((weight) => (
            <Button
              label={
                <Typography width="48px" color="inherit" variant="body-bold">
                  {weight}
                </Typography>
              }
              aria-label={`Remove ${weight}`}
              startIcon={<DismissIcon size="sm" />}
              variant="secondary"
              onClick={() =>
                setSelectedWeights(
                  selectedWeights.filter(
                    (selectedWeight) => selectedWeight !== weight
                  )
                )
              }
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
