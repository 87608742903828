import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import type { JamApiBidModifier } from '@jane/ad-manager/data-access';
import type { BidModifier, BidModifiers } from '@jane/ad-manager/types';
import {
  PLACEMENTS_TO_ZONES_MAP,
  getPlacementFromZone,
  getPlacements,
} from '@jane/ad-manager/util';
import { Flex, Form, Modal, Typography } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import type { FlatAdSubmissionForm } from '../useAdBuilderForm';

export const transformBidModifiers = (
  bidModifiers: BidModifiers
): JamApiBidModifier[] => {
  const output: Array<JamApiBidModifier> = [];

  Object.keys(bidModifiers).forEach((target) => {
    const zone = PLACEMENTS_TO_ZONES_MAP[target];
    if (!zone) {
      return;
    }

    const bidModifier = bidModifiers[target];
    if (!bidModifier.active) {
      return;
    }

    // skip 100% bid modifiers since it's effectively a no-op
    if (bidModifier.type === '%' && bidModifier.amount === 100) {
      return;
    }

    const bidModifiersSubmission: JamApiBidModifier = {
      match: {
        zone,
      },
    };

    if (bidModifier.type === '%') {
      bidModifiersSubmission.multiplier = bidModifier.amount / 100.0;
    } else {
      bidModifiersSubmission.price = bidModifier.amount;
    }

    output.push(bidModifiersSubmission);
  });

  return output;
};

const TYPE_OPTIONS = [
  { label: 'Percentage', value: '%' },
  { label: 'Dollars', value: '$' },
];

const defaultBidModifier = (): BidModifier => {
  return {
    type: '%',
    amount: 100,
    active: false,
  };
};

const BidModifierRowFromTarget = ({ target }: { target: string }) => {
  const {
    field: { onChange: onChangeBidModifiers, value: bidModifiers },
  } = useController<FlatAdSubmissionForm, 'bidModifiers'>({
    name: 'bidModifiers',
  });

  let bidModifier = bidModifiers[target];
  if (!bidModifier) {
    bidModifier = defaultBidModifier();
  }
  useEffect(() => {
    const amount = bidModifier.amount;
    if (!amount) {
      bidModifier.amount = 100;
      onChangeBidModifiers({ ...bidModifiers, [target]: bidModifier });
      return;
    }
    const type = bidModifier.type;
    if (!type) {
      bidModifier.type = '%';
      onChangeBidModifiers({ ...bidModifiers, [target]: bidModifier });
      return;
    }
  }, [bidModifier, bidModifiers, onChangeBidModifiers, target]);

  const active = bidModifier.active;

  return (
    <Table.Row key={'bid-modifier-row-' + target}>
      <Table.Cell>{target}</Table.Cell>
      <Table.Cell>
        <Form.SelectField
          width="174px"
          data-testid={`${target}-type`}
          name={`${target}-type`}
          label="Type"
          labelHidden
          defaultValue={bidModifier.type}
          onChange={(type) => {
            onChangeBidModifiers({
              ...bidModifiers,
              [target]: {
                ...bidModifier,
                type: type,
              },
            });
          }}
          options={TYPE_OPTIONS}
          required={true}
        />
      </Table.Cell>
      <Table.Cell>
        <Form.NumberField
          data-testid={`${target}-amount`}
          name={`${target}-amount`}
          label="Amount"
          labelHidden
          defaultValue={bidModifier.amount}
          onChange={(amount) => {
            onChangeBidModifiers({
              ...bidModifiers,
              [target]: {
                ...bidModifier,
                amount: amount,
              },
            });
          }}
          endUnit={bidModifier.type === '%' ? '%' : ''}
          startUnit={bidModifier.type === '$' ? '$' : ''}
          required={active}
        />
      </Table.Cell>
      <Table.Cell>
        <Form.SwitchField
          name={`${target}-active`}
          label="Active"
          labelHidden
          defaultChecked={bidModifier.active}
          onChange={(active) => {
            onChangeBidModifiers({
              ...bidModifiers,
              [target]: {
                ...bidModifier,
                active: active,
              },
            });
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

interface ErrorMessageProps {
  message: string;
}
const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <Typography color="error" data-testid="bid-modifier-alert">
      {message}
    </Typography>
  );
};

export const transformBidModifiersToJam = (
  bidModifiers: BidModifiers
): JamApiBidModifier[] => {
  const output: Array<JamApiBidModifier> = [];

  Object.keys(bidModifiers).forEach((target) => {
    const zone = PLACEMENTS_TO_ZONES_MAP[target];
    if (!zone) {
      return;
    }

    const bidModifier = bidModifiers[target];
    if (!bidModifier.active) {
      return;
    }

    // skip 100% bid modifiers since it's effectively a no-op
    if (bidModifier.type === '%' && bidModifier.amount === 100) {
      return;
    }

    const bidModifiersSubmission: JamApiBidModifier = {
      match: {
        zone,
      },
    };

    if (bidModifier.type === '%') {
      bidModifiersSubmission.multiplier = bidModifier.amount / 100.0;
    } else {
      bidModifiersSubmission.price = bidModifier.amount;
    }

    output.push(bidModifiersSubmission);
  });

  return output;
};

export const duplicateBidModifiers = (
  apiBidModifiers: JamApiBidModifier[] | undefined | null
): BidModifiers => {
  const bidModifiers = transformBidModifiersFromApi(apiBidModifiers) || {};
  Object.keys(PLACEMENTS_TO_ZONES_MAP).forEach((target) => {
    if (!bidModifiers[target]) {
      bidModifiers[target] = defaultBidModifier();
    }
  });

  return bidModifiers;
};

export const transformBidModifiersFromApi = (
  apiBidModifiers: JamApiBidModifier[] | undefined | null
): BidModifiers | undefined => {
  if (isEmpty(apiBidModifiers)) {
    return;
  }
  const bidModifiers: BidModifiers = {};
  apiBidModifiers?.forEach(({ multiplier, price, match }) => {
    if (!multiplier && !price) return;

    const target = getPlacementFromZone(match.zone);
    if (target) {
      bidModifiers[target] = {
        active: true,
        amount: multiplier ? multiplier * 100 : price ? price : 0,
        type: multiplier ? '%' : '$',
      };
    }
  });

  return bidModifiers;
};

export const AdBidModifiersSection = () => {
  const {
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<FlatAdSubmissionForm>();

  const [bidModifiers] = watch(['bidModifiers']);
  useEffect(() => {
    clearErrors('root.bidModifiers');
  }, [bidModifiers]);

  return (
    <Flex flexDirection="column" data-testid="bid-modifiers-section">
      <Modal.ContentDivider color="grays-light" />
      <Typography variant="body-bold">Bid Modifiers</Typography>
      <Typography variant="body">
        Use bid modifiers on competitive placements and surfaces to help brands
        reach high-value shoppers.
      </Typography>
      {errors.root?.['bidModifiers']?.message?.split('|').map((msg) => {
        return <ErrorMessage message={msg} key={msg} />;
      })}
      <Table minWidth="100%" data-testid="bids-table">
        <Table.Head fixed={false}>
          <Table.Row>
            <Table.HeaderCell>Target</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Active</Table.HeaderCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {getPlacements().map((target) => {
            return <BidModifierRowFromTarget target={target} key={target} />;
          })}
        </Table.Body>
      </Table>
    </Flex>
  );
};
