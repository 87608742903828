import { DigitalMerchandising } from '@jane/ad-manager/components';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Loading } from '@jane/brands/components';
import { Flex } from '@jane/shared/reefer';

export const DigitalMerchandisingPage = () => {
  const {
    selectedBrand,
    isLoading: isSelectedBrandLoading,
    isSuccess: isSelectedBrandSuccess,
  } = useSelectedBrandContext();

  return (
    <Flex flexDirection="column">
      {isSelectedBrandLoading && <Loading />}
      {isSelectedBrandSuccess && selectedBrand && <DigitalMerchandising />}
    </Flex>
  );
};
