import styled from '@emotion/styled';

import type { PriceId, WeightSelectorWeight } from '@jane/shared/models';
import { Box, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { formatCurrency, labelForWeightId } from '@jane/shared/util';

import SvgIcon from '../legacyIcons/legacySvgIcon';
import { useProductCardContext } from '../productCardProvider';

const WeightButtonItem = styled.button<{
  fullWidth?: boolean;
  selected: boolean;
  translateAmount: number;
  width: number;
  xMargin: number;
}>(
  {
    height: 54,
    outline: '0 !important',
    borderRadius: 4,
    position: 'relative',
    textAlign: 'left',
    flex: '0 0 auto',
    padding: '3px 3px',
    cursor: 'pointer',
  },
  ({ width, xMargin, fullWidth, theme }) => [
    {
      border: `1px solid ${theme.colors.grays.light}`,
      borderRadius: theme.borderRadius.lg,
    },
    spacing({ mx: xMargin as any }),
    fullWidth && { flex: '1 0 auto' },
    { minWidth: width },
  ],
  ({ selected, translateAmount, theme }) => [
    {
      backgroundColor: selected
        ? theme.colors.grays.white
        : 'rgba(255, 255, 255, .9)',
      color: selected ? theme.colors.primary.main : theme.colors.grays.black,
      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(255, 255, 255, .8)',
        },
      },
    },
    selected && {
      borderColor: theme.colors.primary.main,
      borderWidth: '3px !important',
    },
    {
      transition: 'transform .2s',
      transform: `translateX(${translateAmount}px)`,
    },
  ]
);

const CheckIconWrapper = styled.span(
  {
    position: 'absolute',
    top: -4,
    right: -3,
    zIndex: 'var(--layers-visible)',
    svg: {
      background: 'white',
      opacity: 1,
      border: '1px solid inherit',
      borderRadius: '50%',
    },
  },
  ({ theme }) => [
    { color: theme ? theme.colors.primary.main : 'rgba(255, 255, 255, 1)' },
  ]
);

const WeightsButtonItems = styled.div<{ fullWidth?: boolean }>(
  spacing({ p: 8 }),
  {
    display: 'flex',
    overflow: 'hidden',
  },
  ({ fullWidth }) =>
    fullWidth && [{ justifyContent: 'space-around', width: '100%' }]
);

interface Props {
  fullWidth?: boolean;
  icon?: boolean;
  itemWidth?: number;
  labelId: string;
  onChange: (priceId: PriceId) => void;
  selectedWeight: PriceId;
  translateAmount?: number;
  weights: WeightSelectorWeight[];
  xMargin?: number;
}

export const WeightPriceItems = ({
  fullWidth,
  itemWidth = 88,
  labelId,
  onChange,
  selectedWeight,
  translateAmount = 0,
  weights,
  xMargin = 2,
}: Props) => {
  const { cartProduct, menuProduct: product } = useProductCardContext();
  const weightsInCart = (cartProduct || []).map((product) => product.price_id);

  return (
    <WeightsButtonItems
      fullWidth={fullWidth}
      role="radiogroup"
      aria-labelledby={labelId}
    >
      {weights.map((weight) => {
        const selected = weight.value === selectedWeight;
        const inCart = weightsInCart.includes(weight.value);
        const weightLabel = labelForWeightId(weight.value, product?.amount);
        return (
          <WeightButtonItem
            key={weight.value}
            selected={selected}
            translateAmount={translateAmount}
            width={itemWidth}
            xMargin={xMargin}
            fullWidth={fullWidth}
            onClick={() => onChange(weight.value)}
            role="radio"
            aria-checked={selected}
          >
            <Box
              mt={selected ? -2 : 0}
              ml={selected ? -2 : 0}
              css={{ textAlign: 'center' }}
            >
              <Typography
                color="text-light"
                as="div"
                mb={-2}
                variant="mini"
                width="100%"
              >
                {weightLabel}
              </Typography>
              <Typography as="div" variant="mini-bold">
                {formatCurrency(weight.price)}
              </Typography>
            </Box>
            {inCart && (
              <CheckIconWrapper>
                <SvgIcon size={18} icon="circleCheckFilled" />
              </CheckIconWrapper>
            )}
          </WeightButtonItem>
        );
      })}
    </WeightsButtonItems>
  );
};
