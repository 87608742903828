import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUser } from '@jane/brands/hooks';

import { checkPermission, getFallbackPath } from './permissions';
import type { BrandPermission } from './permissions';

/**
 * Route parent component that checks if the user has the required permission and redirects if they do not.
 *
 * @example
 * {
 * element: <RedirectUnauthorized requiredPermission="accessAnalytics" />,
 * children: [
 *      {
 *   path: 'analytics',
 *   element: <AnalyticsPage />,
 *      }
 *    ],
 * }
 */

export const RedirectUnauthorized = ({
  requiredPermission,
}: {
  requiredPermission: BrandPermission;
}) => {
  const navigate = useNavigate();
  const { roles } = useUser();

  const redirectPath = getFallbackPath(roles);

  const isAuthorized = checkPermission(roles, requiredPermission);

  useEffect(() => {
    if (!isAuthorized) {
      navigate(redirectPath, { replace: true });
    }
  }, [isAuthorized, navigate, redirectPath]);

  return <Outlet />;
};
