import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Typography } from '@jane/shared/reefer';
import {
  monolithPaths as paths,
  productPhotoMetadata,
} from '@jane/shared/util';

import { ProductImage } from '../ProductImage';
import SvgIcon from '../legacyIcons/legacySvgIcon';
import { DisplayMode, useProductCardContext } from '../productCardProvider';
import { MenuProductCardBackButton } from './backButton';
import { ProductPhotoCorner } from './editView';

const ConfirmationMessage = styled.div({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 100,
  textAlign: 'center',
});

const ProductPhoto = styled.div(
  {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 100,
    overflow: 'hidden',
    height: 100,
    img: {
      objectFit: 'cover',
    },
  },
  ({ theme }) => ({ borderRadius: theme.borderRadius.sm })
);

interface Props {
  height?: number | string;
}

export const MenuProductConfirmationView = ({ height }: Props) => {
  const { appMode, menuProduct, setDisplayMode, routeAppMode } =
    useProductCardContext();
  const navigate = useNavigate();
  const photoMetadata = productPhotoMetadata(menuProduct);

  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayMode(DisplayMode.Product);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex flexDirection="column" height={height} justifyContent="space-between">
      <Box px={8} pt={8}>
        <MenuProductCardBackButton
          onClick={() => {
            setDisplayMode(DisplayMode.Product);
          }}
        />
      </Box>
      <ProductPhoto>
        <ProductPhotoCorner />
        <ProductImage src={photoMetadata.url} alt={menuProduct?.name || ''} />
      </ProductPhoto>
      <ConfirmationMessage>
        <SvgIcon
          color={theme?.colors.primary.main}
          size={{ width: 32, height: 32 }}
          icon="circleCheckFilled"
        />
        <Typography pt={8} pb={24} variant="body-bold">
          Bag updated!
        </Typography>
      </ConfirmationMessage>
      {(appMode === 'headless' || appMode === 'kiosk') && (
        <Flex px={4} mx={8} mb={16}>
          <Button
            onClick={() => routeAppMode && navigate(paths.cart(routeAppMode))}
            aria-label="proceed to bag"
            full
            label="Proceed to Bag"
          />
        </Flex>
      )}
    </Flex>
  );
};
