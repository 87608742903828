import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useContext } from 'react';

import type { BorderRadiusKey } from '../../../theme';
import type { MarginProperties } from '../../../utils';
import { marginCSSVars, toCSSSize } from '../../../utils';
import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import { SkeletonContext } from '../skeleton';
import styles from './skeletonBone.module.css';

export interface SkeletonBoneProps extends ReeferBaseProps, MarginProperties {
  /** Determines the borderRadius of the element, defaults to `lg`. NOTE: `circular` requires equal height and width */
  borderRadius?: BorderRadiusKey | 'circular';

  /** Determines the height of the element, accepts any valid height property */
  height?: string | number;

  /** Determines the width of the element, accepts any valid width property */
  width?: string | number;
}

export function Bone({
  borderRadius,
  className,
  'data-testid': testId,
  id,
  height = '24px',
  style,
  width = '100%',
  ...marginProps
}: SkeletonBoneProps) {
  const { animate } = useContext(SkeletonContext);

  return (
    <div
      className={clsx(className, styles.bone, styles[`bone-${borderRadius}`])}
      data-testid={testId}
      id={id}
      style={
        {
          '--height': toCSSSize(height),
          '--width': toCSSSize(width),
          '--min-width': toCSSSize(width),
          ...marginCSSVars(marginProps),
          ...style,
        } as CSSProperties
      }
      role="presentation"
    >
      <div
        className={clsx(styles['bone-fill'], {
          [styles['bone-fill-animate']]: animate,
        })}
      />
    </div>
  );
}
