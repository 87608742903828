import clsx from 'clsx';
import type { CSSProperties } from 'react';

import {
  fakeButtonProps,
  getColorCSSVar,
  handleEnterKey,
  marginCSSVars,
  toCSSSize,
} from '../../utils';
import styles from './icon.module.css';
import type { IconProps, IconSVGProps } from './icon.types';

/**
 * Reefer offers a set of beautiful icons for use across application.
 */
export function Icon({
  altText,
  className,
  color,
  height,
  icon: Icon,
  id,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  onClick,
  rotate = 'up',
  size = 'md',
  'data-testid': testId,
  style,
  title,
  width,
  ...props
}: IconProps) {
  // Accessibility check
  if (onClick && !altText) {
    throw Error(
      `[reefer/icon]: Icons with onClick functionality should provide an "altText" prop.`
    );
  }

  const iconProps: IconSVGProps = {
    'aria-hidden': !altText,
    'aria-label': altText,
    className: clsx(styles.icon, styles[`rotate-${rotate}`], {
      [styles['icon-fill-color']]: !!color,
      [styles['icon-stroke-color']]: !!color,
    }),
    rotate,
    color,
    id,
    role: 'img',
  };

  const iconStrokeColor = color ? getColorCSSVar(color) : 'currentColor';

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid={testId}
      className={clsx(
        className,
        className?.includes('icon__mono') && styles[className],
        styles.icon_container,
        {
          [styles.icon_container__pointer]: !!onClick,
        }
      )}
      title={title}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      {...props}
      style={
        {
          '--icon-fill-color':
            iconStrokeColor !== 'currentColor' ? iconStrokeColor : undefined,
          '--icon-stroke-color':
            iconStrokeColor !== 'currentColor' ? iconStrokeColor : undefined,
          ...marginCSSVars({ m, mb, ml, mr, mt, mx, my }),
          '--icon-height': height
            ? toCSSSize(height)
            : `var(--icon-sizes-${size})`,
          '--icon-width': width
            ? toCSSSize(width)
            : `var(--icon-sizes-${size})`,
          ...style,
        } as CSSProperties
      }
    >
      <Icon {...iconProps} />
    </div>
  );
}
