import type { PropsWithChildren, ReactNode } from 'react';

import { useUser } from '@jane/brands/hooks';

import { checkPermission } from './permissions';
import type { BrandPermission } from './permissions';

export type RenderIfAuthorizedProps = PropsWithChildren<{
  fallback?: ReactNode;
  requiredPermission: BrandPermission;
}>;

/**
 * Use this component to conditionally render a component based on a permission.
 * Be default if the user does NOT have the required permission `null` will be
 * rendered. An optional `fallback` component can be provided to be rendered
 * instead of `null` for users without the required permission.
 * @example
 * <RenderIfAuthorized requiredPermission="manageUsers" fallback={<AccessDenied />}>
 *   <ProductsList />
 * </RenderIfAuthorized>
 */

export const RenderIfAuthorized = ({
  children,
  fallback,
  requiredPermission,
}: RenderIfAuthorizedProps) => {
  const { roles } = useUser();

  const isAuthorized = checkPermission(roles, requiredPermission);

  if (isAuthorized) return <>{children} </>;

  return fallback ? <>{fallback} </> : null;
};
