import { useState } from 'react';

import type { CategoryOption } from '@jane/shared-b2b/components';
import { CategorySelectInputModal } from '@jane/shared-b2b/components';
import {
  Button,
  DismissIcon,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import type { BrandSpecialRulesVariant } from './productsSection';

export const CategorySelection = ({
  categories,
  variant,
}: {
  categories: CategoryOption[];
  variant: BrandSpecialRulesVariant;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryOption[]
  >([]);

  const handleSave = (updatedCategories: any) => {
    setSelectedCategories(updatedCategories);
    setOpen(false);
  };

  const handleRemoveCategory = (value: string) => {
    const filteredCategories = selectedCategories.filter(
      (category) => category.value !== value
    );
    setSelectedCategories(filteredCategories);
  };

  return (
    <Flex flexDirection="column" gap={16} my={16}>
      <Flex flexDirection="row" width="100%" alignItems="center">
        <Typography>Categories</Typography>
        <Button
          variant="tertiary"
          label="Select"
          aria-label={`Select ${variant} categories`}
          onClick={() => setOpen(true)}
          ml="auto"
        />
        <Modal appId="parent" open={open} onRequestClose={() => setOpen(false)}>
          <CategorySelectInputModal
            categoryOptions={categories}
            defaultShowSubItems={false}
            onSave={handleSave}
            selectedCategories={selectedCategories}
          />
        </Modal>
      </Flex>
      {selectedCategories && selectedCategories.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {selectedCategories.map((category) => (
            <Button
              label={category.label}
              aria-label={`remove ${category.label}`}
              startIcon={<DismissIcon size="sm" />}
              variant="secondary"
              onClick={() => handleRemoveCategory(category.value)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
