import { forwardRef } from 'react';

import { emailRegex, passwordRegex, telRegex, urlRegex } from '../../../utils';
import type { TextFieldProps } from '../fields/field.types';
import { TextField } from '../fields/textField/textField';
import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormTextFieldProps
  extends Omit<TextFieldProps, 'children'>,
    FormFieldProps {}

/**
 * Text fields allow users to enter text into a UI.
 *
 * Use this component *within forms* for inputs of `type`:
 * `email`, `password`, `tel`, `text` or `url`.
 *
 * Default pattern validations are configured for `email`, `password`, `tel` and `url` fields.
 * If necessary, these default pattern validations can be overridden with the `pattern` and `errorMessage` props.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`TextField`](/story/components-forms-fields-textfield--default).*
 */
export const FormTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  (
    {
      autocomplete,
      autocompleteFooter,
      autocompleteOptions,
      autoFocus = false,
      defaultValue = '',
      disabled = false,
      helperText,
      errorMessage,
      errorOnBlur = false,
      label,
      labelHidden = false,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onSelection,
      pattern,
      placeholder,
      readOnly = false,
      required = false,
      shouldUnregister,
      'data-testid': testId,
      type = 'text',
      validate,
      width = 'auto',
      ...props
    }: FormTextFieldProps,
    forwardedRef
  ) => {
    let myPattern;
    let myErrorMessage;

    if (type === 'email') {
      myPattern = emailRegex;
      myErrorMessage = 'Please enter an email address.';
    } else if (type === 'url') {
      myPattern = urlRegex;
      myErrorMessage = 'Please enter a URL.';
    } else if (type === 'tel') {
      myPattern = telRegex;
      myErrorMessage = 'Please enter a valid US phone number.';
    } else if (type === 'password') {
      myPattern = passwordRegex;
      myErrorMessage =
        'Please enter a password with a minimum of 10 characters.';
    }

    myPattern = pattern ? pattern : myPattern;
    myErrorMessage = pattern ? errorMessage : myErrorMessage;

    return (
      <FormFieldWrapper
        defaultValue={defaultValue}
        errorMessage={myErrorMessage}
        errorOnBlur={errorOnBlur}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        pattern={myPattern}
        render={({ children, field }) => (
          <TextField
            autocomplete={autocomplete}
            autocompleteFooter={autocompleteFooter}
            autocompleteOptions={autocompleteOptions}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            disabled={disabled}
            helperText={helperText}
            label={label}
            labelHidden={labelHidden}
            name={field.name}
            onBlur={(value) => {
              onBlur && onBlur(value);
              field.onBlur();
            }}
            onChange={(value) => {
              onChange && onChange(value);
              field.onChange(value);
            }}
            onSelection={onSelection}
            onUpdate={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={(el) => {
              field.ref(el);
              if (forwardedRef) {
                if (typeof forwardedRef === 'function') {
                  forwardedRef(el);
                } else {
                  forwardedRef.current = el;
                }
              }
            }}
            data-testid={testId}
            type={type}
            value={field.value}
            width={width}
            {...props}
          >
            {children}
          </TextField>
        )}
        required={required}
        shouldUnregister={shouldUnregister}
        validate={validate}
      />
    );
  }
);
