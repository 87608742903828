import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { getAccessibilityProps } from '../../../internal/utils';
import { getColorCSSVar, marginCSSVars, toCSSSize } from '../../../utils';
import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import type { CardProps } from '../card';
import styles from './cardGroup.module.css';

export interface CardGroupProps
  extends Omit<CardProps, 'onClick'>,
    ReeferBaseProps {
  /** Direction of `Card.Content` within the `Group`, defaults to column */
  direction?: 'column' | 'row';
}

/**
 * The `Card.Group` component is a wrapper component used for displaying a styled card group in either a row or column with an optional `border`, `box-shadow` and `separator` lines between content.
 * */

export function CardGroup({
  ariaLabel,
  ariaLabelledBy,
  border = 'none',
  borderWidth = '1px',
  children,
  className,
  direction = 'column',
  flat = false,
  height = 'fit-content',
  id,
  role,
  'data-testid': testId,
  width = 'fit-content',
  ...marginProps
}: CardGroupProps) {
  const a11yProps = getAccessibilityProps({ ariaLabel, ariaLabelledBy, role });

  return (
    <div
      className={clsx(className, styles.cardGroup, {
        [styles.cardGroup__flat]: flat,
      })}
      id={id}
      style={
        {
          '--cardGroup-border':
            border === 'none'
              ? 'none'
              : `${borderWidth} solid ${getColorCSSVar(border)}`,
          '--cardGroup-flex-direction': direction,
          '--cardGroup-height': toCSSSize(height),
          ...marginCSSVars(marginProps),
          '--cardGroup-width': toCSSSize(width),
        } as CSSProperties
      }
      data-testid={testId}
      {...a11yProps}
    >
      {children}
    </div>
  );
}
