import styled from '@emotion/styled';
import { useState } from 'react';

import { Hide } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { CategoryCheckbox } from './CategoryCheckbox';
import type { CategoryOption } from './CategorySelectInputModal';

const CategoryDivider = styled.hr(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.colors.grays.light,
  height: 1,
}));

export const StyledSubCategoryList = styled.ul(({ theme }) => ({
  maxHeight: '360px',
  overflow: 'auto',
  ...spacing({ ml: 40, mb: 12 }),

  borderLeft: `1px solid ${theme.colors.grays.light}`,
}));

export const CategoryListItem = ({
  category,
  defaultShowSubItems,
  selectedCategories,
  onChange,
  isLastCategory,
}: {
  category: CategoryOption;
  defaultShowSubItems: boolean;
  isLastCategory: boolean;
  onChange: (value: string) => void;
  selectedCategories: CategoryOption[];
}) => {
  const { label, subItems, value } = category;
  const [showSubItems, setShowSubItems] = useState(defaultShowSubItems);
  const hasSubItems = subItems && subItems.length > 0;

  const isIndeterminate = subItems
    ?.map((subItem: CategoryOption) =>
      selectedCategories.some(
        (category: CategoryOption) => category.value === subItem.value
      )
    )
    .includes(true);

  const isTopLevelChecked =
    hasSubItems &&
    selectedCategories.some(
      (category: CategoryOption) => category.value === value
    );

  const renderedItem = (children?: React.ReactNode) => {
    return (
      <CategoryCheckbox
        category={category}
        children={
          <>
            {children}
            {!isLastCategory && <CategoryDivider />}
          </>
        }
        onChange={onChange}
        selectedCategories={selectedCategories}
        hasSubItems={hasSubItems}
        indeterminate={isIndeterminate}
        showSubcategories={showSubItems}
        setShowSubcategories={setShowSubItems}
        subCategoryValues={subItems}
      />
    );
  };

  const renderedSubItems = subItems?.map((item: CategoryOption) => {
    return (
      <CategoryCheckbox
        key={item.label}
        isParentCategoryChecked={isTopLevelChecked}
        category={item}
        onChange={onChange}
        isSubItem
        parentCategoryValue={value}
        selectedCategories={selectedCategories}
      />
    );
  });

  if (hasSubItems) {
    return renderedItem(
      <Hide isHidden={!showSubItems}>
        <StyledSubCategoryList aria-label={`${label}: subcategories`}>
          {renderedSubItems}
        </StyledSubCategoryList>
      </Hide>
    );
  }

  return renderedItem();
};
