import { Box, Flex, Typography } from '@jane/shared/reefer';

import type { StoreSearchMode } from './StoreSelectModal';

export const NoStoresFound = ({
  searchMode,
}: {
  searchMode: StoreSearchMode;
}) => (
  <Box
    width="100%"
    height="512px"
    border="grays-light"
    borderRadius="sm"
    data-testid="no-stores-found"
  >
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={'100%'}
    >
      <Typography variant="header-bold">No Stores available</Typography>
      <Typography>
        As you search for
        {searchMode === 'storeId' ? ' IDs' : ' store names'}, stores will be
        displayed here.
      </Typography>
    </Flex>
  </Box>
);
