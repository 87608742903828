import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

import { useInfiniteBrands } from '@jane/ad-manager/data-access';
import { useIsInView } from '@jane/ad-manager/hooks';
import type { ProductBrand } from '@jane/ad-manager/types';
import { Loading } from '@jane/brands/components';
import { SearchField } from '@jane/shared/reefer';

import { BrandSearchRow } from './BrandSearchRow';

const FetchNextPage = styled.div<{ hasNextPage: boolean }>(
  ({ hasNextPage, theme }) => ({
    height: hasNextPage ? 96 : 0,
    position: 'relative',
  })
);

interface BrandSearchProps {
  selectBrand: (brand: ProductBrand) => void;
  selectedBrand: ProductBrand;
}
export const BrandSearch = ({
  selectedBrand,
  selectBrand,
}: BrandSearchProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isInView = useIsInView(ref);
  const [query, setQuery] = useState('');
  const getBrands = useInfiniteBrands(query);

  useEffect(() => {
    if (isInView) getBrands.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <div data-testid="brand-search">
      <SearchField
        label="search for another brand"
        labelHidden={true}
        name="brand-name-search"
        onChange={setQuery}
        mb={24}
      />
      {getBrands.isLoading && <Loading />}
      {getBrands.isSuccess && (
        <div data-testid="brand-search-results">
          <BrandSearchRow
            brand={selectedBrand}
            isSelected={true}
            selectBrand={selectBrand}
          />
          {getBrands.data.pages
            .flatMap(({ data }) => data)
            .filter((brand) => brand.id !== selectedBrand.id)
            .map((brand) => (
              <BrandSearchRow
                key={brand.id}
                brand={brand}
                isSelected={false}
                selectBrand={selectBrand}
              />
            ))}
        </div>
      )}
      <FetchNextPage
        data-testid="fetch-next-page"
        ref={ref}
        hasNextPage={getBrands.hasNextPage || false}
      >
        {getBrands.isFetchingNextPage && <Loading />}
      </FetchNextPage>
    </div>
  );
};
