import {
  StyledFilterListButton,
  StyledFilterListItem,
} from '@jane/shared-b2b/components';
import { Link } from '@jane/shared/reefer';

interface DeselectFilterButtonsProps {
  inList?: boolean;
  label: string;
  onClick: () => void;
}

export const DeselectFiltersButton = ({
  label,
  onClick,
  inList = true,
}: DeselectFilterButtonsProps) => {
  if (inList) {
    return (
      <StyledFilterListItem>
        <StyledFilterListButton onClick={onClick}>
          <Link typography="body">{label}</Link>
        </StyledFilterListButton>
      </StyledFilterListItem>
    );
  }

  return <Link onClick={onClick}>{label}</Link>;
};
