import { NewTable as BaseNewTable } from './newTable/newTable';
import { Table as BaseTable } from './table';
import type { TableProps } from './table.types';
import { TableBody } from './tableBody/tableBody';
import { TableCell } from './tableCell/tableCell';
import { TableFoot } from './tableFoot';
import { TableHead } from './tableHead';
import { TableHeaderCell } from './tableHeaderCell/tableHeaderCell';
import { TableRow } from './tableRow/tableRow';

export type { TableProps };

export const NewTable = Object.assign(BaseNewTable, {
  Body: TableBody,
  Cell: TableCell,
  Foot: TableFoot,
  Head: TableHead,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
});

export const Table = Object.assign(BaseTable, {
  Body: TableBody,
  Cell: TableCell,
  Foot: TableFoot,
  Head: TableHead,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
});
