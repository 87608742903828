import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSelectedSubdivision = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSubdivision = searchParams.get('subdivision') || '';

  const updateSelectedSubdivision = useCallback(
    (subdivision: string) => {
      setSearchParams((searchParams) => {
        const updated = new URLSearchParams(searchParams);

        updated.delete('subdivision');

        if (subdivision) {
          updated.set('subdivision', subdivision);
        }

        return updated;
      });
    },
    [setSearchParams]
  );

  return {
    selectedSubdivision,
    updateSelectedSubdivision,
  };
};
