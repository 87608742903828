import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import type { AuthUser } from '@jane/brands/data-access';

export const useIdentifyDatadogUser = (user?: AuthUser) => {
  useEffect(() => {
    if (!user) return;

    datadogRum.setUser({
      id: user.id,
      email: user.email,
      roles: user.roles,
    });
  }, [user]);
};
