import styled from '@emotion/styled';
import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from '@jane/brands/components';
import { useBrandsNavigate } from '@jane/brands/hooks';
import {
  useInfiniteProducts,
  useProductFilters,
  useSelectedBrands,
  useUpdateNextProductId,
} from '@jane/catalog-cms/hooks';
import { Banner, Box, Flex, InfoIcon, Typography } from '@jane/shared/reefer';
import { hidden } from '@jane/shared/reefer-emotion';
import { NewTable, Table } from '@jane/shared/reefer-table';

import { ProductsListRow } from './ProductsListRow';
import { SortableHeaderCell } from './SortableHeaderCell';

const COLUMN_MIN_WIDTH = '120px';

const SrOnlyCaption = styled.caption({ ...hidden(true) });

export const ProductsList = () => {
  const nav = useBrandsNavigate();
  const { filters } = useProductFilters();
  const { selectedBrandIds } = useSelectedBrands();
  const { productId } = useParams();
  const {
    data,
    fetchNextPage,
    isFetched,
    isSuccess,
    isError,
    error,
    hasNextPage,
  } = useInfiniteProducts({ ...filters, brand: selectedBrandIds });
  const loadNextRef = useRef(null);
  const productsHeader = data
    ? `${data.totalCount} Product${data.totalCount !== 1 ? 's' : ''}`
    : 'Products';

  // Throw any error from product fetching to trigger error boundary
  if (isError) {
    throw error;
  }

  const hasNoProducts = isFetched === true && data?.products.length === 0;
  const showLoadingProducts = hasNextPage && isFetched && isSuccess;

  useUpdateNextProductId(productId, data?.products);

  // Initialize infinite scroll observer
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        const lastProduct = entries[0];

        if (lastProduct.isIntersecting) {
          fetchNextPage();
          observer.unobserve(lastProduct.target);
        }
      },
      { rootMargin: '300px' }
    );
  }, [fetchNextPage]);

  // When data changes re-observe last product
  useEffect(() => {
    if (loadNextRef.current) {
      observer.observe(loadNextRef.current);
    }
    return () => observer.disconnect();
  }, [observer, data]);

  return (
    <>
      <NewTable freezeFirstColumn>
        <colgroup>
          <col style={{ width: '400px' }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
          <col style={{ minWidth: COLUMN_MIN_WIDTH }} />
        </colgroup>
        <SrOnlyCaption>Products List</SrOnlyCaption>
        <Table.Head>
          <Table.Row>
            <SortableHeaderCell text={productsHeader} sortField="name" />
            <HeaderCell text="Id" />
            <HeaderCell text="Category" />
            <HeaderCell text="Subcategory" />
            <HeaderCell text="Brand Category" />
            <HeaderCell text="Brand" />
            <SortableHeaderCell text="Status" sortField="notice_kind" />
            <HeaderCell text="Created At" />
            <HeaderCell text="Last Modified" />
            <SortableHeaderCell text="Stores" sortField="storeCount" />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <>
            {data?.products?.map((product) => (
              <ProductsListRow
                product={product}
                key={product.id}
                selected={productId === product.id}
                onClick={() => nav.editProduct(product.id)}
              />
            ))}
            {/* Show loading more products animation for infinite scroll */}
            {showLoadingProducts && (
              <tr ref={loadNextRef}>
                <td colSpan={10} style={{ padding: 8 }}>
                  <Box height={81}>
                    <Loading
                      color="purple"
                      size="lg"
                      background="grays-white"
                      data-testid="fetch-more-loader"
                    />
                  </Box>
                </td>
              </tr>
            )}
          </>
        </Table.Body>
      </NewTable>
      {/* Show loader during initial fetch */}
      {!isFetched && (
        <div style={{ height: '300px' }}>
          <Loading
            background="grays-white"
            color="purple"
            data-testid="product-list-loader"
          />
        </div>
      )}
      {/* Display message when there are no products */}
      {hasNoProducts && <NoProductsMessage />}
    </>
  );
};

const HeaderCell = ({ text }: { text: string }) => {
  return (
    <Table.HeaderCell>
      <Typography variant="caps" as="span">
        {text}
      </Typography>
    </Table.HeaderCell>
  );
};

const NoProductsMessage = () => {
  return (
    <Flex justifyContent={'center'} py={64}>
      <Banner
        typography="body-bold"
        icon={<InfoIcon />}
        label="No products found, try adjusting the filters above."
      />
    </Flex>
  );
};
