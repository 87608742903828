import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import type {
  BrandPageState,
  BrandTheme,
  ProductBrand,
} from '@jane/ad-manager/types';
import {
  combineCustomMedia,
  getCustomMediaDescription,
  getCustomMediaUrl,
  getRelativeDate,
} from '@jane/ad-manager/util';
import { brandsPaths, marketplacePaths } from '@jane/brands/util';
import { MultilineTruncate } from '@jane/shared/components';
import {
  Button,
  Card,
  Flex,
  PartnerLogo,
  Typography,
} from '@jane/shared/reefer';

const ColorBox = styled.div<{ colorHex: string | undefined }>(
  ({ colorHex, theme }) => ({
    height: 96,
    backgroundColor: colorHex,
  })
);

interface Props {
  brand: ProductBrand;
  brandTheme: BrandTheme;
}
export const PreviewCard = ({ brand, brandTheme }: Props) => {
  const navigate = useNavigate();
  const {
    colorHex,
    customImages,
    customVideo,
    description,
    draft,
    logoUrl,
    productBrandName,
    updatedAt,
  } = brandTheme;
  let footerText;
  if (draft) footerText = 'Your changes have not been published yet.';
  else if (updatedAt) footerText = `Last updated ${getRelativeDate(updatedAt)}`;

  const customMedia = combineCustomMedia(customImages, customVideo)[0];

  const buttonLabel = draft ? 'Continue editing' : 'Edit brand page';

  const goToBrandThemeEditor = (id: number) => {
    const editorState: BrandPageState = { state: { brand } };
    navigate(brandsPaths.editBrandTheme(id), editorState);
  };

  return (
    <Flex flexDirection="column" alignItems="center" gap={16} width="100%">
      <Card width="100%">
        {customMedia ? (
          <Card.Image
            alt={
              getCustomMediaDescription(customMedia) ||
              `${productBrandName} background image`
            }
            height="240px"
            src={getCustomMediaUrl(customMedia)}
          />
        ) : (
          <ColorBox data-testid="color-box" colorHex={colorHex} />
        )}
        <Card.Content>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap={16}
            mt={-64}
            mb={40}
          >
            <div style={{ zIndex: 1 }}>
              <PartnerLogo
                name={productBrandName}
                variant="brand"
                image={logoUrl}
              />
            </div>
            <Typography variant="title-bold" textAlign="center">
              {productBrandName}
            </Typography>
            <MultilineTruncate lines={3}>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {description ? (
                  <Typography variant="body" textAlign="center">
                    {description}
                  </Typography>
                ) : (
                  <Typography
                    variant="body"
                    textAlign="center"
                    color="grays-mid"
                  >
                    No description
                  </Typography>
                )}
              </div>
            </MultilineTruncate>
            <Flex gap={16} flexWrap="wrap" justifyContent="center">
              <Button
                disabled={!brand.normalizedName}
                label="View brand page"
                variant="secondary"
                href={
                  brand.normalizedName
                    ? marketplacePaths.brandPage(brand)
                    : undefined
                }
              />
              <Button
                label={buttonLabel}
                variant="secondary"
                onClick={() => goToBrandThemeEditor(brand.id)}
              />
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
      {footerText && <Typography color="grays-mid">{footerText}</Typography>}
    </Flex>
  );
};
