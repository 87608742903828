import styled from '@emotion/styled';
import type * as ReactTable from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useInView } from 'react-intersection-observer';

import type { Product } from '@jane/ad-manager/data-access';
import { Box, Flex, Loading } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

export type ProductTableWithMeta = ReactTable.Table<Product>;

const StyledLoading = styled(Loading)({
  position: 'relative',
  top: 'inherit',
  left: 'inherit',
  margin: 'auto',
  transform: 'inherit',
});

const TableLoading = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={'100%'}
  >
    <StyledLoading size="sm" data-testid="product-table-loading" />
  </Flex>
);

const ProductsFetching = () => (
  <Table.Row>
    <th colSpan={5}>
      <Flex height={72} flexDirection="column" alignItems="center">
        <StyledLoading data-testid="product-table-fetching" size="sm" />
      </Flex>
    </th>
  </Table.Row>
);

const TableBody = ({
  table,
  isFetching,
  fetchMore,
}: {
  fetchMore: () => void;
  isFetching?: boolean;
  table: ProductTableWithMeta;
}) => {
  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView) fetchMore();
    },
  });
  return (
    <>
      <Table.Body>
        <>
          {table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              data-testid={`select-products-table-row-${row.id}`}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
          <div ref={fetchMoreRef} style={{ height: '1px' }} />
        </>
      </Table.Body>
      {isFetching && <ProductsFetching />}
    </>
  );
};

export const ProductTable = ({
  fetchMore,
  table,
  isFetching,
  isLoading,
}: {
  fetchMore: () => void;
  isFetching?: boolean;
  isLoading?: boolean;
  table: ProductTableWithMeta;
}) => {
  return (
    <>
      <Box style={{ flex: 'none' }}>
        <Table scrollable>
          <Table.Head fixed>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeaderCell
                    key={header.id}
                    width={`${header.getSize()}px`}
                  >
                    {!header.isPlaceholder &&
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <>
            {!isLoading && (
              <TableBody
                table={table}
                fetchMore={fetchMore}
                isFetching={isFetching}
              />
            )}
          </>
        </Table>
      </Box>
      {isLoading && <TableLoading />}
    </>
  );
};
