import { z } from 'zod';

import {
  zBrandSpecialUserSegmentIds,
  zKindCondition,
} from '@jane/shared/types';

const zBrandSpecialRules = z.object({
  excludes: z
    .array(
      z
        .object({
          kinds: z.array(zKindCondition),
          lineages: z.array(z.string()),
          product_ids: z.array(z.number()).nullable(),
          store_ids: z.array(z.number()).nullable(),
          user_segment_ids: zBrandSpecialUserSegmentIds,
          weights: z.array(z.string()),
        })
        .partial()
    )
    .optional(),
  includes: z
    .array(
      z
        .object({
          kinds: z.array(zKindCondition),
          lineages: z.array(z.string()),
          product_ids: z.array(z.number()).nullable(),
          store_ids: z.array(z.number()).nullable(),
          user_segment_ids: zBrandSpecialUserSegmentIds,
          weights: z.array(z.string()),
        })
        .partial()
    )
    .optional(),
});

export const BrandSpecialSchema = z.object({
  active: z.boolean(),
  aeropay_location_id: z.string().nullable().optional(),
  created_at: z.string(),
  description: z.string(),
  discount_dollar_amount: z.number(),
  discount_label: z.string(),
  discount_percent: z.number(),
  discount_target_price: z.number(),
  discount_type: z.string(),
  enabled: z.boolean(),
  end_time: z.string().nullable(),
  excluded_app_modes: z.array(z.string()).nullable(),
  id: z.number(),
  photo: z
    .object({
      id: z.number(),
      position: z.number().nullable(),
      urls: z.object({
        extraLarge: z.string(),
        medium: z.string(),
        original: z.string(),
        small: z.string(),
      }),
    })
    .nullable(),
  product_brand: z.object({
    id: z.number(),
    logo_url: z.string().nullable(),
    name: z.string(),
  }),
  product_brand_id: z.number(),
  rules: zBrandSpecialRules,
  special_type: z.string(),
  start_time: z.string().nullable(),
  terms: z.string().nullable(),
  title: z.string(),
  updated_at: z.string(),
});

export const BrandSpecialsResponseSchema = z.object({
  brand_specials: z.array(BrandSpecialSchema),
});

export type BrandSpecial = z.infer<typeof BrandSpecialSchema>;

export interface FetchBrandSpecialParams {
  id: number;
}

export interface FetchBrandSpecialsParams {
  discountType?: string[];
  endTime?: string;
  limit?: number;
  offset?: number;
  productBrandIds?: number[];
  remainingBudgetEnd?: number;
  remainingBudgetStart?: number;
  specialStatus?: string[];
  startTime?: string;
}
