import styled from '@emotion/styled';

import type { BoxProps, TypographyProps } from '@jane/shared/reefer';
import { Box, Flex, Typography } from '@jane/shared/reefer';

const fontColor: TypographyProps['color'] = 'grays-black';
const backgroundColor: BoxProps['background'] = 'grays-ultralight';

const StatisticsEntryGrid = styled.div<{ columns: number }>((props) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
  width: '100%',
}));

export interface StatisticsEntryProps {
  label: string;
  valueDisplay: string | number;
}

const StatisticsEntry = ({ label, valueDisplay }: StatisticsEntryProps) => (
  <Box mr={12}>
    <Typography
      data-testid={`statistics-entry-value-${label.toLowerCase()}`}
      branded
      color={fontColor}
      variant="header-bold"
      as="div"
    >
      {valueDisplay}
    </Typography>
    <Typography
      data-testid={`statistics-entry-label-${label.toLowerCase()}`}
      color={fontColor}
      variant="mini"
      as="h2"
    >
      {label}
    </Typography>
  </Box>
);

const StatisticsListRow = ({
  entries,
}: {
  entries: StatisticsEntryProps[];
}) => (
  <StatisticsEntryGrid columns={entries.length}>
    {entries.map(({ label, valueDisplay }) => (
      <StatisticsEntry key={label} label={label} valueDisplay={valueDisplay} />
    ))}
  </StatisticsEntryGrid>
);

interface Props {
  entries: StatisticsEntryProps[];
  loading?: boolean;
  onTarget?: boolean;
}

export const StatisticsDisplay = ({ entries, onTarget, loading }: Props) => {
  const StyledFlex = styled(Flex)`
    div {
      opacity: ${loading ? 0.5 : 1};
    }
  `;

  return (
    <StyledFlex
      grow
      flexDirection="column"
      px={40}
      py={24}
      borderRadius="lg"
      background={backgroundColor}
      gap={24}
      data-testid="statistics"
    >
      {onTarget && <Typography color="palm-dark">On target</Typography>}
      <StatisticsListRow entries={entries} />
    </StyledFlex>
  );
};
