/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import type { Filters } from '@jane/shared-b2b/components';
import {
  ProductSelectModal,
  useProductSelectColumns,
} from '@jane/shared-b2b/components';
import { Button, Flex, Modal, Typography } from '@jane/shared/reefer';

import type { BrandSpecialRulesVariant } from './productsSection';
import { SelectedProducts } from './selectedProducts';

// To be moved/replaced with Product schema/type once query is implemented
interface ProductPhotoUrls {
  extraLarge: string;
  medium: string;
  original: string;
  small: string;
}

interface ProductPhoto {
  id: string;
  position: number;
  urls?: ProductPhotoUrls;
}

export interface Product {
  brand: string;
  category?: string;
  id: string;
  lineage: string;
  name: string;
  outOfStock?: boolean;
  photos: ProductPhoto[];
  subcategory?: string;
  weights?: string[];
}

export const ProductSelection = ({
  filters = {},
  selectedFilters,
  selectedProducts,
  variant,
}: {
  filters: Filters;
  selectedFilters: unknown;
  selectedProducts: Product[];
  variant: BrandSpecialRulesVariant;
}) => {
  const [open, setOpen] = useState(false);

  const [data, setData] = useState(() => [
    // ...
  ]);
  const [searchIds, setSearchIds] = useState<string[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  // getSelectedFilters(selectedFilters)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // getSelectedProducts<any>(selectedProducts)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const columnVisibility = {
    name: true,
    brand: false,
    id: false,
    category: true,
    subcategory: true,
    lineage: true,
    weight: true,
    outOfStock: false,
  };

  const productTableColumns = useProductSelectColumns({
    filters,
    productCount: 0,
  });

  const productTable = useReactTable({
    columns: productTableColumns,
    data,
    state: { columnFilters, columnVisibility, sorting, rowSelection },
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id?.toString() || '',
    enableRowSelection: true,
    enableGlobalFilter: false,
  });

  const hasSelectedProducts = selectedProducts.length > 0;

  return (
    <Flex flexDirection="column" gap={16} my={16}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Typography>
          Products{hasSelectedProducts ? ` (${selectedProducts.length})` : ''}
        </Typography>
        <Button
          variant="tertiary"
          label="Select"
          aria-label={`Select ${variant} products`}
          onClick={() => setOpen(true)}
        />
        <Modal
          open={open}
          onRequestClose={() => setOpen(false)}
          appId="parent"
          variant="flex"
        >
          <ProductSelectModal
            isFetchingNextPage={false}
            onNextPage={() => console.log('Next page')}
            onSaveClick={() => console.log('Save')}
            productTable={productTable}
            setSearchIds={setSearchIds}
          />
        </Modal>
      </Flex>

      {hasSelectedProducts && (
        <SelectedProducts
          products={selectedProducts}
          onRemove={() => console.log('remove product')}
          variant={variant}
        />
      )}
    </Flex>
  );
};
